// @flow
import {
    actionTypes,
} from '../actions/types';

import type { Action, AuthObject } from '../flowTypes';

const INITIAL_STATE: AuthObject = {
    uid: '',
    user: undefined,
    userIsInitialized: false,
    localAccount: '',
    networkId: 0,
    profile: { username: '', avatarNumber: 1000, gamesPlayed: 0 },
};

// eslint-disable-next-line
export default (state: AuthObject = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case actionTypes.UPDATE_USER:
            return {
                ...state,
                uid: action.payload.uid,
                user: action.payload.user,
                userIsInitialized: true,
            };
        case actionTypes.UPDATE_LOCAL_ACCOUNT:
            return {
                ...state,
                localAccount: action.payload.localAccount,
            };
        case actionTypes.UPDATE_WEB_3:
            return {
                ...state,
                web3: action.payload.web3,
            };
        case actionTypes.UPDATE_LOCAL_ACCOUNT_AND_WEB_3:
            return {
                ...state,
                web3: action.payload.web3,
                localAccount: action.payload.localAccount,
            };
        case actionTypes.UPDATE_NETWORK_ID:
            return {
                ...state,
                networkId: action.payload.networkId,
            };
        case actionTypes.UPDATE_PROFILE:
            return {
                ...state,
                profile: action.payload.profile,
            }
        default:
            return state;
    }
};
