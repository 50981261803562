import * as React from 'react';
import { styles } from '../../generalStyles';
import { lightBorderNoPaddingOnTopLessPadding } from '../../UI Resources';
import ModalComponent from '../ModalComponent';
import NumericInput from 'react-numeric-input';
import { connect } from 'react-redux';
import { AuthObject, GlobalState, LeagueObject } from '../../flowTypes';

import banana from '../../assets/banana.png';

type Props = {
    showModal: boolean,
    onRequestClose: () => void,
    ticker: string,
    buyTicker: (quantity: number) => void,
    max: number,
    auth: AuthObject,
    league: LeagueObject,
};

type State = {
    quantity: number,
};

class BuyTicker extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            quantity: 0,
        };
    }

    onRequestClose = () => {
        const { onRequestClose } = this.props;

        this.setState({ quantity: 0 });
        onRequestClose();
    }

    render() {
        const { showModal, ticker, buyTicker, max } = this.props;
        const { quantity } = this.state;

        return (
            <ModalComponent
                showModal={showModal}
                onRequestClose={this.onRequestClose}
            >
                <div style={{ ...styles.marginBottomExtra, ...styles.header3, ...styles.marginBottom, ...styles.inverseText }}><b>{`BUY ${ticker}`}</b></div>
                {lightBorderNoPaddingOnTopLessPadding()}
                <div style={{ ...styles.flexRowSpaceBetween, display: 'flex', ...styles.bold, ...styles.text, ...styles.marginBottom, ...styles.centerAligned }}>

                    <span style={{ ...styles.inverseText }}>Quantity</span>
                    <NumericInput
                        defaultValue={max}
                        min={1}
                        mobile={true}
                        format={(value: any) => {
                            if (typeof value === 'number') {
                                return value?.toFixed(2) || '';
                            }
                            return parseFloat(value)?.toFixed(2) || '';
                        }}
                        width={50}
                        max={max}
                        style={{
                            wrap: {
                                display: 'flex',
                                marginLeft: 20,
                            },
                            input: {
                                maxWidth: 200,
                                ...styles.inverseText,
                                minHeight: 40,
                                border: '1px solid red !important',
                                ...styles.bold,
                            },
                            arrowUp: {
                                color: 'white',
                                right: 0,
                            },
                            arrowDown: {
                                color: 'white',
                                left: 0,
                            },
                        }}
                        onChange={(quantity) => {
                            this.setState({ quantity: Math.min(quantity || max, max) });
                        }}
                    />
                </div>
                <div style={{ ...styles.inverseText, ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.bold, ...styles.marginBottom }}>
                    Cost per share
                    <div style={{ ...styles.flex, }}><img alt='banana' style={{ height: 20, ...styles.marginRight }} src={banana} />1</div>
                </div>
                <div style={{ ...styles.inverseText, ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.bold, ...styles.marginBottomExtra }}>
                    Total Cost
                    <div style={{ ...styles.flex, }}><img alt='banana' style={{ height: 20, ...styles.marginRight }} src={banana} />{(quantity || max).toFixed(2)}</div>
                </div>
                <div
                    className='clickable'
                    onClick={() => {
                        buyTicker(quantity || max);
                        this.onRequestClose();
                    }}
                    style={{ ...styles.roundedCorners, ...styles.baseButton, ...styles.enabledButton, backgroundColor: '#00F9EA', ...{ cursor: 'pointer', justifyContent: 'center', display: 'flex' } }}
                >
                    <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{'BUY'}</p>
                </div>
            </ModalComponent>
        )
    }
}

const mapStateToProps = (state: GlobalState) => {
    return {
        auth: state.auth,
        league: state.league,
    };
};

export default connect(mapStateToProps, {})(BuyTicker)
