import { batch } from 'react-redux';
import { Action, Leagues, Portfolio, Portfolios, SickestApes, Stock } from '../flowTypes';
import { actionTypes } from './types';

export const updatePortfolios = (portfolios: Portfolios, stocks: Stock[]): Action => {

    for (const key of Object.keys(portfolios)) {
        const portfolioList = portfolios[key];
        updatePortfoliosWithStockPrices(portfolioList, stocks);

    }

    const payload = { portfolios };
    return { type: actionTypes.UPDATE_PORTFOLIOS, payload };
};

export const updateLeaguePlayerCounts = (playerCounts: number[]): Action => {
    return { type: actionTypes.UPDATE_PLAYER_COUNTS, payload: playerCounts };
}

export const updateLeagues = (leagues: Leagues, stocks: Stock[], uid: string): any => {
    return async (dispatch: any) => {

        const portfolios: Portfolios = {};
        const payload = { leagues };
        for (const leagueId of Object.keys(leagues)) {
            const league = leagues[leagueId];

            const portfolioList = Object.keys(league.leagueStandings || {})
                .map(key => {
                    const uid = key.split('-')[0];
                    const index = parseInt(key.split('-')[1]);
                    league.leagueStandings[key].uid = uid;
                    league.leagueStandings[key].index = index;
                    return league.leagueStandings[key];
                });
            updatePortfoliosWithStockPrices(portfolioList, stocks);

            league.sortedLeagueStandings = portfolioList
                .sort((a, b) => b.totalExpectedBananas - a.totalExpectedBananas)
                .map((p, pIndex) => {
                    league.leagueStandings[`${p.uid}-${p.index}`].ranking = pIndex;
                    return p;
                });

            portfolios[leagueId] = portfolioList.filter(p => p.uid === uid).sort((a, b) => (a.index || 0) - (b.index || 0));
        }

        batch(() => {
            dispatch({ type: actionTypes.UPDATE_LEAGUES, payload });
            dispatch({ type: actionTypes.UPDATE_PORTFOLIOS, payload: { portfolios } });
        })
    }
};

export const updateStocks = (stocks: Stock[]): Action => {
    const payload = { stocks };
    return { type: actionTypes.UPDATE_STOCKS, payload };
};

export const updateSickestApes = (sickestApes: SickestApes): Action => {
    const payload = { sickestApes };
    return { type: actionTypes.UPDATE_SICKEST_APES, payload };
};

export const updateNonces = (nonces: { [leagueId: number]: number }): Action => {
    const payload = { nonces };
    return { type: actionTypes.UPDATE_NONCES, payload };
};

export const updateShowConnectModal = (showConnectModal: boolean): Action => {
    const payload = { showConnectModal };
    return { type: actionTypes.UPDATE_SHOW_CONNECT_MODAL, payload };
}

export const updateLeagueWinners = (leagueWinners: string[]): Action => {
    return { type: actionTypes.UPDATE_WINNERS, payload: leagueWinners };
}

const updatePortfoliosWithStockPrices = (portfolioList: Portfolio[], stocks: Stock[]) => {
    for (const portfolio of portfolioList) {
        const assets = portfolio.assets || [];
        const totalBananas = portfolio.totalBananas;

        let totalExtraBananas = 0;
        for (const asset of assets) {
            const stock = stocks.find(s => s.ticker === asset.ticker) || { close: 0 };
            const bananas = asset.quantity * (stock.close / asset.price);
            totalExtraBananas += bananas;
        }

        portfolio.totalExpectedBananas = totalBananas + totalExtraBananas;
    }
}