import * as React from 'react';
import { styles } from '../../generalStyles';
import '../../index.css';
import _ from 'lodash';
import { AuthObject, GlobalState, LeagueObject } from '../../flowTypes';
import { connect } from 'react-redux';
import { getIsAfterHours, getIsDecidingTime, getLeagueEndDateAndTime, getSuffix, lightBorderLessPadding } from '../../UI Resources';
import { useLocation } from 'react-router';
import TradeSection from './TradeSection';
import { buttonStyles } from './buttonStyles';
import { FixedSizeList as List } from "react-window";
import AutoSizer from 'react-virtualized-auto-sizer';

import ticket from '../../assets/ticket.png';

import rightArrow from '../../assets/rightArrow.png';
import leftArrow from '../../assets/leftArrow.png';
import { BOX_PADDING, GREEN, LEADERBOARD_MARGIN_LEFT, LEAGUE_TITLES, MAX_WIDTH, RED, REMAINDER_HEIGHT } from '../../constants';
import { MechanicalCounter } from "mechanical-counter";

import medal from '../../assets/medal.png';
import bananaBunch from '../../assets/bananaBunch.png';
import WrappedAnimatingComponent from '../WrappedAnimatingComponent';
import { isMobile } from 'react-device-detect';
import Countdown from 'react-countdown';


type Props = {
    auth: AuthObject,
    league: LeagueObject,
    leagueId: number
};

type State = {
    index: number,
    showModal: boolean,
    tradingPortfolioIndex: number,
    tradingLeagueId: number,
    marketClosed: boolean,
};

class Play extends React.Component<Props, State> {

    scrollContainer: React.RefObject<HTMLDivElement>;

    firstDiv: React.RefObject<HTMLDivElement>;
    secondDiv: React.RefObject<HTMLDivElement>;
    thirdDiv: React.RefObject<HTMLDivElement>;


    constructor(props: Props) {
        super(props);

        this.scrollContainer = React.createRef();
        this.firstDiv = React.createRef();
        this.secondDiv = React.createRef();
        this.thirdDiv = React.createRef();


        this.state = {
            index: 0,
            showModal: false,
            tradingPortfolioIndex: 0,
            tradingLeagueId: 0,
            marketClosed: getIsAfterHours(),
        };
    }

    componentDidMount() {
        setInterval(() => {
            const { marketClosed } = this.state;
            const isMarketClosed = getIsAfterHours();

            if (marketClosed !== isMarketClosed) {
                this.setState({ marketClosed: false });
            }
        }, 1000);
    }

    getLeagueRow = (props: { index: number, style: any }) => {
        const { league, leagueId } = this.props;
        const { leagues } = league || {};

        const portfolio = (leagues[leagueId]?.sortedLeagueStandings || [])[props.index];

        if (!portfolio) return null;

        const portfolioNumber = portfolio.index;
        const gains = (portfolio.totalExpectedBananas / 100 - 1) * 100;

        return (
            <div style={{ ...props.style }}>
                <div style={{ ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.fullWidth }}>
                    <div style={{ ...styles.flex, maxWidth: 'calc(100% - 80px)' }}>
                        <div style={{ ...styles.inverseText, ...styles.bold, ...styles.flex, ...styles.centeredContainer, minWidth: 45 }}>{props.index + 1}{!isMobile && getSuffix(props.index + 1)}</div>
                        <div style={{ ...styles.inverseText, ...styles.flexGrow, ...styles.bold, ...styles.flex, ...styles.centerAligned, marginLeft: LEADERBOARD_MARGIN_LEFT, maxWidth: 'calc(100% - 45px)' }}>
                            <img alt='avatar' style={{ width: 40, borderRadius: 20, ...styles.marginRight }} src={require(`../../assets/avatars-small/${portfolio.profile?.avatarNumber}.png`).default}></img>
                            <div style={{ overflowWrap: 'break-word', overflow: 'hidden', ...(isMobile && styles.smallText) }}>{portfolio.profile?.username} {portfolioNumber ? `(#${portfolioNumber + 1})` : ''}</div>
                        </div>
                    </div>
                    <div style={{ ...styles.flex, flexDirection: 'column', ...styles.centeredContainer, minWidth: 80 }}>
                        <div style={{ display: "flex", width: '100%', justifyContent: 'flex-end', alignItems: "center", ...styles.bold, ...styles.inverseText }}>
                            <MechanicalCounter text={portfolio.totalExpectedBananas.toFixed(2).split('.')[0]} />.<MechanicalCounter text={portfolio.totalExpectedBananas.toFixed(2).split('.')[1]} />
                        </div>
                        <div style={{ display: "flex", width: '100%', ...styles.smallText, justifyContent: 'flex-end', alignItems: "center", ...styles.bold, color: gains < 0 ? RED : GREEN }}>
                            {gains < 0 ? '' : '+'}<MechanicalCounter text={gains.toFixed(2).split('.')[0]} />.<MechanicalCounter text={gains.toFixed(2).split('.')[1]} />%
                        </div>
                    </div>
                </div>
                {lightBorderLessPadding(`league-standings-border-${portfolio.uid}-${portfolio.index}`)}
            </div>
        );
    }

    getLeagueStandings() {
        const { league, leagueId } = this.props;
        const { leagues } = league || {};

        const portfolios = (leagues[leagueId]?.sortedLeagueStandings || []);

        return (
            <div style={{ ...styles.flex, ...styles.flexGrow, flexDirection: 'column', ...styles.fullWidth, paddingLeft: BOX_PADDING, paddingRight: BOX_PADDING }}>
                <div style={{ ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.fullWidth, ...styles.marginBottomExtra }}>
                    <div style={{ ...styles.inverseText, ...styles.bold }}>RANK</div>
                    <div style={{ ...styles.inverseText, ...styles.flexGrow, ...styles.bold, marginLeft: LEADERBOARD_MARGIN_LEFT }}>PLAYER</div>
                    <div style={{ ...styles.inverseText, textAlign: 'right', ...styles.bold }}>BANANAS</div>
                </div>
                <div style={{ ...styles.flexGrow }}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                className="List"
                                height={height}
                                itemCount={portfolios.length}
                                itemSize={80.5}
                                width={width}
                                itemData={portfolios}
                                itemKey={(item, data) => `${data[item].uid}-${data[item].index}` || ''}
                            >
                                {this.getLeagueRow}
                            </List>)}
                    </AutoSizer>
                </div>
            </div>
        );
    }

    getShowCountdown() {
        const today = new Date();
        const dayOfWeek = today.getDay();
        return !(dayOfWeek === 5 && getIsAfterHours());
    }

    render(): any {
        const { leagueId, league } = this.props;
        const { portfolios } = league || {};

        const portfolioList = portfolios[leagueId] || [];
        const { tradingPortfolioIndex, marketClosed } = this.state;

        const isSinglePortfolio = portfolioList.length <= 1;
        const onFirstBag = tradingPortfolioIndex <= 0;
        const onLastBag = tradingPortfolioIndex >= portfolioList.length - 1;

        const totalExpectedBananas = portfolioList[tradingPortfolioIndex]?.totalExpectedBananas ?? 100;

        const gains = ((totalExpectedBananas) / 100 - 1) * 100;

        const leaguePortfolio = portfolioList[tradingPortfolioIndex];
        const ranking = (leaguePortfolio?.ranking || 0) + 1

        const hasPortfolio = portfolioList?.length;

        return (
            <WrappedAnimatingComponent>
                <div style={{ width: '100%', marginTop: isMobile ? 0 : 10, minHeight: REMAINDER_HEIGHT, maxHeight: REMAINDER_HEIGHT, overflow: 'scroll', ...styles.flexColumnCentered }}>
                    <div style={{ ...styles.inverseText, ...styles.bold, ...styles.centerText, ...styles.header2, marginTop: 10, ...styles.marginBottomSmall }}><img alt='ticket-icon' style={{ height: 30, filter: 'invert(1)', marginBottom: -7, marginRight: 5 }} src={ticket} />{LEAGUE_TITLES[leagueId]}{!isMobile && marketClosed && ' (MARKET CLOSED)'}</div>
                    {!isMobile && this.getShowCountdown() && <div style={{ ...styles.bold, display: 'flex', justifyContent: 'center', ...styles.inverseText, ...styles.marginBottomExtra }}>League Ends: &nbsp;<Countdown date={getLeagueEndDateAndTime()} daysInHours={false} /></div>}
                    {!isMobile && getIsDecidingTime() && <div style={{ ...styles.bold, display: 'flex', justifyContent: 'center', ...styles.inverseText, ...styles.marginBottomExtra }}>League will be decided soon.</div>}
                    <div style={{ ...styles.fullWidth, ...styles.marginTop, flexGrow: 1, ...styles.flex, ...styles.centerJustified, flexWrap: 'wrap' }}>
                        {Boolean(hasPortfolio) && <div className={`margin-right-desktop ${isMobile ? '' : 'play-container'}`} style={{ ...styles.flex, flexDirection: 'column', ...styles.fullWidth, maxWidth: 500, ...buttonStyles.boxContainer, ...styles.padding, paddingLeft: 0, paddingRight: 0, ...styles.marginBottomExtra }}>
                            <div style={{ ...styles.flexRowSpaceBetween, ...styles.flex, ...styles.centerAligned, ...styles.marginBottomExtra }}>
                                <div style={{ paddingLeft: BOX_PADDING }}>
                                    <div style={{ ...styles.inverseText, ...styles.bold, ...styles.header1 }}>{isSinglePortfolio ? 'PORTFOLIO' : `PORTFOLIO #${tradingPortfolioIndex + 1}`}</div>
                                    <div style={{ ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.centerAligned }}>
                                        <div style={{ ...styles.inverseText, ...styles.bold, marginRight: 23, textAlign: 'left', width: 40, ...styles.flex, marginLeft: -3 }}><img alt='medal' src={medal} style={{ filter: 'invert(1)', height: 20 }} />{ranking}{getSuffix(ranking)}</div>
                                        <div style={{ ...styles.flex, justifyContent: 'flex-end' }}>
                                            <div style={{ ...styles.inverseText, ...styles.flex, ...styles.centerAligned, ...styles.bold, marginRight: 5 }}>
                                                <img alt='banana' src={bananaBunch} style={{ height: 20, ...styles.marginRight }} />
                                                <MechanicalCounter text={`${totalExpectedBananas.toFixed(2).split('.')[0]}`} />.<MechanicalCounter text={`${totalExpectedBananas.toFixed(2).split('.')[1]}`} />
                                            </div>
                                            <div style={{ ...styles.flex, ...styles.centerAligned, ...styles.smallText, marginTop: 4, ...styles.bold, color: gains < 0 ? RED : GREEN, marginRight: 2 }}>
                                                {gains < 0 ? '' : '+'}<MechanicalCounter text={`${gains.toFixed(2).split('.')[0]}`} />.<MechanicalCounter text={`${gains.toFixed(2).split('.')[1]}`} />%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!isSinglePortfolio && <div style={{ ...styles.flexRow, ...styles.flex, ...styles.centerAligned, ...styles.marginRightExtra }}>
                                    <img alt='left' className='invert' onClick={onFirstBag ? _.noop : () => this.setState({ tradingPortfolioIndex: tradingPortfolioIndex - 1 })} style={{ opacity: !onFirstBag ? 1 : .5, height: 30, ...styles.marginRight, cursor: 'pointer' }} src={leftArrow}></img>
                                    <img alt='right' className='invert' onClick={onLastBag ? _.noop : () => this.setState({ tradingPortfolioIndex: tradingPortfolioIndex + 1 })} style={{ opacity: !onLastBag ? 1 : .5, height: 30, cursor: 'pointer' }} src={rightArrow}></img>
                                </div>}
                            </div>
                            <TradeSection marketClosed={marketClosed} leagueId={leagueId} portfolioIndex={tradingPortfolioIndex} portfolio={portfolioList[tradingPortfolioIndex]} />
                        </div>}
                        <div className={isMobile ? '' : 'play-container'} style={{ ...styles.flex, flexDirection: 'column', ...styles.fullWidth, maxWidth: hasPortfolio ? 500 : MAX_WIDTH, ...buttonStyles.boxContainer, ...styles.padding, paddingLeft: 0, paddingRight: 0, ...(styles.marginBottomExtra), ...(isMobile && hasPortfolio && { height: 315 }) }}>
                            <div style={{ ...styles.inverseText, ...styles.bold, ...styles.header1, paddingLeft: BOX_PADDING, ...styles.marginBottomExtra }}>LEAGUE STANDINGS</div>
                            {this.getLeagueStandings()}
                        </div>
                    </div>
                </div>
            </WrappedAnimatingComponent >
        );
    }
}

const mapStateToProps = (state: GlobalState) => {
    return {
        auth: state.auth,
        league: state.league,
    };
};

const WrappedComponent = (props: any) => {
    const location = useLocation();
    const { pathname } = location;
    const leagueId = parseInt(_.last(pathname.split('/')) || '0');

    return <Play leagueId={leagueId} {...props} />
}

export default connect(mapStateToProps, {})(WrappedComponent)