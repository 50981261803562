import React from 'react';

// @ts-ignore
import { Transition } from 'react-transition-group';
import { styles } from '../generalStyles';

type Props = {
};

type State = {
    showConnect: boolean,
};

export default class WrappedAnimatingComponent extends React.Component<Props, State> {


    constructor(props: Props) {
        super(props);
        this.state = {
            showConnect: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showConnect: true });
        }, 300);
    }


    render() {
        const { children } = this.props;
        const { showConnect } = this.state;

        const duration = 300;

        const defaultStyle = {
            transition: `all ${duration}ms ease-in-out`,
            opacity: 0,
        };

        const connectTransitionStyles: { [key: string]: any } = {
            entering: { opacity: 1, marginTop: 0 },
            entered: { opacity: 1, marginTop: 0 },
            exiting: { opacity: 0, marginTop: 0 },
            exited: { opacity: 0, marginTop: 0 },
        };

        return (
            <Transition in={showConnect} timeout={700}>
                {(state: any) =>
                    <div style={{
                        ...defaultStyle,
                        ...connectTransitionStyles[state],
                        ...styles.fullWidth,
                        ...styles.flex,
                        ...styles.centeredContainer
                    }}>
                        {children}
                    </div>
                }
            </Transition>
        );
    }
}