import * as React from 'react';
import '../../index.css';
import _ from 'lodash';
import { AuthObject, GlobalState, LeagueObject } from '../../flowTypes';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { styles } from '../../generalStyles';
import { buttonStyles } from './buttonStyles';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Dots } from 'react-activity';
import { BOX_PADDING, DULL_TEXT_COLOR_DARKER, GREEN, MAX_WIDTH, RED, REMAINDER_HEIGHT, TEXT_COLOR } from '../../constants';
import { lightBorderLessPadding } from '../../UI Resources';

import { updateProfile } from '../../actions/authActions';
import { getDatabase, ref, set } from 'firebase/database';
import WrappedAnimatingComponent from '../WrappedAnimatingComponent';

type Props = {
    auth: AuthObject,
    league: LeagueObject,
    leagueId: number,
    updateProfile: typeof updateProfile,
};

type State = {
    index: number,
    showModal: boolean,
    tradingPortfolioIndex: number,
    tradingLeagueId: number,
    newUsername: string,
    changingUsername: boolean,
    usernameTaken: boolean,
};

class Me extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            index: 0,
            showModal: false,
            tradingPortfolioIndex: 0,
            tradingLeagueId: 0,
            newUsername: '',
            changingUsername: false,
            usernameTaken: false,
        };
    }

    updateProfileAvatarNumberOnline(avatarNumber: number) {
        const { auth } = this.props;
        const { uid } = auth || {};

        const database = getDatabase();
        const avatarRef = ref(database, `/profiles/${uid}/avatarNumber`);
        set(avatarRef, avatarNumber);
    }

    changeUsername = async () => {

        this.setState({ changingUsername: true });
        const { newUsername: username } = this.state;
        const functions = getFunctions();

        const setUsername = httpsCallable(functions, 'auth-setUsername');
        const { usernameTaken = false } = ((await setUsername({ username })).data as { usernameTaken: boolean });
        this.setState({ changingUsername: false, usernameTaken });
    }

    getLeagueStandings(ranking: number) {
        const { auth, league } = this.props;
        const { uid } = auth || {};
        const { sickestApes } = league || {};

        return (
            <>
                <div className='max-height-desktop' style={{ ...styles.flex, flexDirection: 'column', ...styles.fullWidth, paddingLeft: BOX_PADDING, paddingRight: BOX_PADDING }}>
                    <div style={{ ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.fullWidth, ...styles.marginBottomExtra }}>
                        <div style={{ ...styles.inverseText, width: '33.3%', ...styles.bold }}>RANK</div>
                        <div style={{ ...styles.inverseText, width: '33.3%', ...styles.centerText, ...styles.bold }}>USERNAME</div>
                        <div style={{ ...styles.inverseText, width: '33.3%', textAlign: 'right', ...styles.bold }}>GAINS</div>
                    </div>
                    <div style={{ overflow: 'scroll' }}>
                        {Object.keys(sickestApes || {})
                            .filter(key => key === uid)
                            .map((key) => {
                                const ape = sickestApes[key];
                                const gains = (ape.totalBananas / 100 - 1) * 100;

                                return (
                                    <div key={`leagueStanding-${key}`}>
                                        <div key={`leagueStanding-${key}`} style={{ ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.fullWidth }}>
                                            <div style={{ ...styles.inverseText, width: '33.3%', ...styles.bold }}>{ranking + 1}</div>
                                            <div style={{ ...styles.inverseText, width: '33.3%', ...styles.centerText, ...styles.bold }}>{ape.profile.username}</div>
                                            <div style={{ ...styles.inverseText, width: '33.3%', textAlign: 'right', ...styles.bold, color: gains < 0 ? RED : GREEN }}>{gains < 0 ? '' : '+'}{gains.toFixed(2)}%</div>
                                        </div>
                                        {lightBorderLessPadding(`league-standings-border-${key}`)}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </>

        )
    }

    render(): any {

        const { auth } = this.props;
        const { profile } = auth || {};
        const { username, avatarNumber } = profile || {};
        const { changingUsername, newUsername } = this.state;
        const canChangeUsername = newUsername && newUsername !== username;
        const { usernameTaken } = this.state;

        return (
            <WrappedAnimatingComponent>
                <div style={{ width: '100%', overflow: 'scroll', maxHeight: REMAINDER_HEIGHT, minHeight: REMAINDER_HEIGHT, ...styles.flex, ...styles.centerAligned, flexDirection: 'column', marginTop: 10, }}>
                    <div style={{ ...styles.fullWidth, maxWidth: MAX_WIDTH, ...buttonStyles.boxContainer, ...styles.padding, paddingLeft: BOX_PADDING, paddingRight: BOX_PADDING }}>
                        <div style={{ ...styles.bold, ...styles.header1, ...styles.marginBottomExtra, color: '#00F9EA' }}>USERNAME</div>
                        {usernameTaken && <div style={{ ...styles.bold, ...styles.inverseText, ...styles.marginBottom }}>That username is taken.</div>}
                        <div style={{ ...styles.fullWidth, paddingLeft: 0, paddingRight: 0, ...styles.marginBottomExtra, ...styles.flex }}>
                            <input
                                defaultValue={username}
                                maxLength={20}
                                style={{ maxHeight: 40, ...styles.marginRight, ...styles.fullWidth, ...styles.header4, borderBottom: `2px solid ${DULL_TEXT_COLOR_DARKER}`, borderWidth: '0px 0px 1px 0px', backgroundColor: 'transparent', fontStyle: 'italic', ...styles.inverseText, padding: 10 }}
                                placeholder='Set your username'
                                onChange={(e) => this.setState({ newUsername: e.target.value })}
                            />
                            <div
                                onClick={canChangeUsername ? this.changeUsername : _.noop}
                                className='clickable no-select'
                                style={{ ...styles.marginBottom, ...styles.roundedCorners, ...styles.baseButton, ...(!canChangeUsername ? { border: `2px solid ${DULL_TEXT_COLOR_DARKER}`, color: DULL_TEXT_COLOR_DARKER } : { backgroundColor: '#00F9EA' }), ...styles.fullWidth, ...{ cursor: 'pointer', justifyContent: 'center', display: 'flex', maxWidth: 100, minHeight: 40, paddingLeft: 0, paddingRight: 0 } }}
                            >
                                {changingUsername ?
                                    <Dots color={TEXT_COLOR} style={{ ...styles.flex, ...styles.centeredContainer }} /> :
                                    <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{'SAVE'}</p>
                                }
                            </div>
                        </div>
                    </div >
                    <div style={{ ...styles.fullWidth, maxWidth: MAX_WIDTH, ...buttonStyles.boxContainer, ...styles.padding, marginTop: 10, paddingLeft: BOX_PADDING, paddingRight: BOX_PADDING, ...styles.marginBottomExtra }}>
                        <div style={{ ...styles.bold, ...styles.header1, ...styles.marginBottomExtra, color: '#00F9EA' }}>AVATAR</div>
                        <div style={{ ...styles.fullWidth, paddingLeft: 0, paddingRight: 0, ...styles.marginBottomExtra, ...styles.flex, flexWrap: 'wrap' }}>
                            {Array.from(Array(63).keys()).map((a, index) => {
                                const leftCorner = index === 0;
                                const rightCorner = index === 2;
                                const bottomLeftCorner = index === 60;
                                const bottomRightCorner = index === 62;

                                return (
                                    <img
                                        alt={`pic-${index}`}
                                        className='clickable'
                                        onClick={() => this.updateProfileAvatarNumberOnline(index)}
                                        style={{
                                            width: '33%',
                                            ...(leftCorner && { borderTopLeftRadius: 10 }),
                                            ...(rightCorner && { borderTopRightRadius: 10 }),
                                            ...(bottomLeftCorner && { borderBottomLeftRadius: 10 }),
                                            ...(bottomRightCorner && { borderBottomRightRadius: 10 }),
                                            border: index === avatarNumber ? '4px solid #00F9EA' : ''

                                        }}
                                        src={require(`../../assets/avatars-medium/${index}.png`).default}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </WrappedAnimatingComponent>
        );
    }
}

const mapStateToProps = (state: GlobalState) => {
    return {
        auth: state.auth,
        league: state.league,
    };
};

const WrappedComponent = (props: any) => {
    const location = useLocation();
    const { pathname } = location;
    const leagueId = parseInt(_.last(pathname.split('/')) || '0');

    return <Me leagueId={leagueId} {...props} />
}

export default connect(mapStateToProps, { updateProfile })(WrappedComponent)