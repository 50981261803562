import { isMobile } from "react-device-detect";

export const BACKGROUND_COLOR = '#ffffff';
export const BORDER_COLOR = '#dddddd';
export const DULL_TEXT_COLOR = '#dddddd';
export const DULL_TEXT_COLOR_DARKER = '#999999';
export const PRIMARY_BUTTON_BACKGROUND_COLOR = '#00F9EA';
export const SECONDARY_BUTTON_BACKGROUND_COLOR = 'white';
export const LIGHT_BACKGROUND_COLOR = '#f5f5f5';
export const TEXT_COLOR = '#000000';

export const BORDER_RADIUS = 4;
export const BOX_PADDING = isMobile ? 15 : 20;
export const LEADERBOARD_MARGIN_LEFT = isMobile ? 15 : 40;

export const SMART_CONTRACT = require("./SmartContract.json");
export const SMART_CONTRACT_ADDRESS = "0xAF3d26e4Ab4559ED461cf4A86B2E7C94d302be9D";
export const INFURA_ID = '680e977a6603483b91c7803475fea5ee';

export const CHAIN_ID = 137;
export const CHAIN_HEX = '0x89';
export const CHAIN_NAME = 'Polygon';
export const CHAIN_EXPLORER = "https://polygonscan.com/#";
export const INFURA_URL = `https://polygon-mainnet.infura.io/v3/${INFURA_ID}`;


export const SYMBOL = 'MATIC';
export const LEAGUE_IDS = [0, 1];
export const LEAGUE_PRICES = [1, 1];
export const LEAGUE_TITLES = [`${LEAGUE_PRICES[0]} ${SYMBOL} ALPHA LEAGUE`, `${LEAGUE_PRICES[1]} ${SYMBOL} 50/50 LEAGUE`];
export const LEAGUE_SHORT_TITLES = [`ALPHA LEAGUE`, `50-50 LEAGUE`];
export const LEAGUE_SUBTITLES = [`ONE APE TAKES IT ALL`, `TOP HALF DOUBLES UP`];

export const BOX_COLOR = '#1D2432'
export const GREEN = '#56FC7B';
export const RED = '#FB2C5E';

export const MAX_WIDTH = 525;


export const REMAINDER_HEIGHT = isMobile ? 'calc(100vh - 110px)' : 'calc(100vh - 150px)';
