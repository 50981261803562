// @flow
import {
    actionTypes,
} from '../actions/types';

import type { Action, LeagueObject } from '../flowTypes';

const INITIAL_STATE: LeagueObject = {
    portfolios: {},
    leagues: {},
    stocks: [],
    nonces: { 0: 0, 1: 0, 2: 0 },
    sickestApes: {},
    showConnectModal: false,
    leagueWinners: ['', '', ''],
    leaguePlayerCounts: [0, 0, 0],
};

// eslint-disable-next-line
export default (state: LeagueObject = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PORTFOLIOS:
            return {
                ...state,
                portfolios: action.payload.portfolios
            };
        case actionTypes.UPDATE_LEAGUES:
            return {
                ...state,
                leagues: action.payload.leagues,
            };
        case actionTypes.UPDATE_STOCKS:
            return {
                ...state,
                stocks: action.payload.stocks
            };
        case actionTypes.UPDATE_NONCES:
            return {
                ...state,
                nonces: action.payload.nonces
            }
        case actionTypes.UPDATE_SICKEST_APES:
            return {
                ...state,
                sickestApes: action.payload.sickestApes
            };
        case actionTypes.UPDATE_SHOW_CONNECT_MODAL:
            return {
                ...state,
                showConnectModal: action.payload.showConnectModal
            };
        case actionTypes.UPDATE_WINNERS:
            return {
                ...state,
                leagueWinners: action.payload
            };
        case actionTypes.UPDATE_PLAYER_COUNTS:
            return {
                ...state,
                leaguePlayerCounts: action.payload
            };
        default:
            return state;
    }
};
