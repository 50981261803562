import Web3 from 'web3';
import { Action, Profile, User } from '../flowTypes';
import { actionTypes } from './types';

export const updateUser = (user: User): Action => {
    const payload = { uid: user.uid, user };
    return { type: actionTypes.UPDATE_USER, payload };
};

export const updateProfile = (profile: Profile): Action => {
    const payload = { profile };
    return { type: actionTypes.UPDATE_PROFILE, payload };
};

export const updateLocalAccount = (localAccount: string): Action => {
    const payload = { localAccount };
    return { type: actionTypes.UPDATE_LOCAL_ACCOUNT, payload };
};

export const updateWeb3 = (web3: Web3): Action => {
    const payload = { web3 };
    return { type: actionTypes.UPDATE_WEB_3, payload };
};

export const updateLocalAccountAndWeb3 = (localAccount: string, web3: Web3): Action => {
    const payload = { localAccount, web3 };
    return { type: actionTypes.UPDATE_LOCAL_ACCOUNT_AND_WEB_3, payload };
};

export const updateNetworkId = (networkId: number): Action => {
    const payload = { networkId };
    return { type: actionTypes.UPDATE_NETWORK_ID, payload };
}


