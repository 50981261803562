import React from 'react';
import Modal from 'react-modal';
import { isMobile } from 'react-device-detect';
import { buttonStyles } from './Navigation/buttonStyles';
import { BORDER_RADIUS } from '../constants';

const customStyles = {
    content: isMobile ? {
        left: '0px',
        right: '0px',
        bottom: '0',
        top: 'auto',
        outline: 'none',
        border: '0px solid #ccc',
        ...buttonStyles.boxContainer,
        borderRadius: `${BORDER_RADIUS}px ${BORDER_RADIUS}px 0px 0px`,
        maxHeight: 500,
    } : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        border: '0px solid #ccc',
        maxWidth: 550,
        maxHeight: 500,
        width: '100%',
        ...buttonStyles.boxContainer
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: 20

    }
};

type Props = {
    showModal: boolean,
    onRequestClose: () => void,

};

export default class ModalComponent extends React.Component<Props> {

    render() {

        const { showModal, onRequestClose, children } = this.props;

        return (
            <Modal
                isOpen={showModal}
                contentLabel="Example Modal"
                style={customStyles}
                onRequestClose={onRequestClose}
                ariaHideApp={false}
            >
                {children}
            </Modal>
        );
    }
}
