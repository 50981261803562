import * as React from 'react';
import { styles } from '../../generalStyles';
import { buttonStyles } from './buttonStyles';
import '../../index.css';
import ModalComponent from '../ModalComponent';
import { AuthObject, GlobalState, LeagueObject, Portfolio } from '../../flowTypes';
import { connect } from 'react-redux';
import { BOX_PADDING, CHAIN_ID, LEAGUE_IDS, LEAGUE_PRICES, LEAGUE_SUBTITLES, LEAGUE_TITLES, MAX_WIDTH, REMAINDER_HEIGHT, SMART_CONTRACT, SMART_CONTRACT_ADDRESS, SYMBOL, TEXT_COLOR } from '../../constants';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { updatePortfolios, updateShowConnectModal } from '../../actions/leagueActions';
import { Link } from 'react-router-dom';

import medal from '../../assets/medal.png';
import ticket from '../../assets/ticket.png';
import WrappedAnimatingComponent from '../WrappedAnimatingComponent';
import { isMobile, isMobileSafari } from 'react-device-detect';
import { Dots } from 'react-activity';
import { delay } from '../../UI Resources';

type Props = {
    auth: AuthObject,
    league: LeagueObject,
    updatePortfolios: typeof updatePortfolios,
    updateShowConnectModal: typeof updateShowConnectModal,
};

type State = {
    index: number,
    showModal: boolean,
    tradingPortfolio?: Portfolio,
    tradingPortfolioIndex: number,
    tradingLeagueId: number,
    showLeagueStandings: { [leagueId: number]: boolean },
    joiningLeagues: { [leagueId: number]: boolean },
};


class Leagues extends React.Component<Props, State> {

    scrollContainer: React.RefObject<HTMLDivElement>;

    firstDiv: React.RefObject<HTMLDivElement>;
    secondDiv: React.RefObject<HTMLDivElement>;
    thirdDiv: React.RefObject<HTMLDivElement>;

    divs: React.RefObject<HTMLDivElement>[];

    constructor(props: Props) {
        super(props);

        this.scrollContainer = React.createRef();
        this.firstDiv = React.createRef();
        this.secondDiv = React.createRef();
        this.thirdDiv = React.createRef();

        this.divs = [this.firstDiv, this.secondDiv, this.thirdDiv];

        this.state = {
            index: 0,
            showModal: false,
            tradingPortfolioIndex: 0,
            tradingLeagueId: 0,
            showLeagueStandings: {},
            joiningLeagues: {},
        };
    }

    onJoinLeague = async () => {
        const { auth } = this.props;
        const { web3, localAccount } = auth || {};
        const { joiningLeagues, index } = this.state;
        this.setState({ showModal: false });

        if (!web3) return;

        const nonce = await web3?.eth.getTransactionCount(localAccount, 'latest'); //get latest nonce
        const gasPrice = `${Math.floor(parseInt(await web3?.eth.getGasPrice()) * 1.5)}`; // bump a bit for speed
    
        const date = new Date();
        const convertedDate = convertTZ(date, "America/New_York");
        const isWeekend = convertedDate.getDay() === 6
            || convertedDate.getDay() === 0
            || (convertedDate.getDay() === 5 && convertedDate.getHours() >= 17);

        const isFlatFee = index === 1;

        // const value = web3?.utils.toBN('1000000000000000000').muln(Math.pow(10, index)).muln(isWeekend ? 1 : (1 + .2 * convertedDate.getDay()));
        const val = 1000000000000000000 * LEAGUE_PRICES[index] * (isFlatFee ? 1.1 : (isWeekend ? 1 : (1 + .2 * convertedDate.getDay())));
        const nftContract = new web3.eth.Contract(SMART_CONTRACT.abi, SMART_CONTRACT_ADDRESS);

        web3?.eth.sendTransaction({
            from: localAccount,
            to: SMART_CONTRACT_ADDRESS,
            nonce,
            gasPrice,
            gas: '500000',
            value: val,
            data: nftContract.methods.joinLeague(index).encodeABI(),
        }).on('sending', () => {
            this.setState({ joiningLeagues: { ...joiningLeagues, [index]: true } });
        }).on('error', () => {
            this.setState({ joiningLeagues: { ...joiningLeagues, [index]: false } });
        }).on('transactionHash', (r) => {
            console.log('transactionHash');
            window.localStorage.setItem(`currentTx-${index}`, r);
            console.log(r);
        }).on('receipt', (r) => {
            console.log('receipt');
            console.log(r);
        }).on('confirmation', async (c, r) => {
            console.log('confirmation');
            console.log(c, r);
            if (c === 3) {
                const functions = getFunctions();
                const getPortfolios = httpsCallable(functions, 'leagues-getPortfolios');
                await getPortfolios({ uid: localAccount, leagueId: index });
                await delay(1000);
                this.setState({ joiningLeagues: { ...joiningLeagues, [index]: false } });
            }
        });
    }

    getLeague(title: string, players: number, divider: number, ref: React.LegacyRef<HTMLDivElement> | undefined, leagueId: number, isLast?: boolean) {

        const { league, auth, updateShowConnectModal } = this.props;
        const { uid, networkId } = auth || {};
        const { portfolios, leagueWinners, leagues } = league || {};

        const leagueHasPlayers = leagues[leagueId]?.sortedLeagueStandings?.length;
        const { joiningLeagues } = this.state;

        const hasPortfolio = Boolean(portfolios[leagueId]?.length);
        const notConnected = Boolean(!uid) || networkId !== CHAIN_ID;

        return (
            <div ref={ref} style={{ ...(isLast ? styles.marginBottomExtra : styles.marginBottom) }}>
                <div style={{ ...buttonStyles.boxContainer, ...styles.paddingExtra, paddingLeft: 20, paddingRight: 20 }}>
                    <div style={{ ...styles.flexColumnCentered, ...styles.centerText }}>
                        {Boolean(leagueWinners[leagueId]) && <div style={{ ...styles.bold, ...styles.inverseText, ...styles.flex, ...styles.centerAligned }}><img alt='medal' src={medal} style={{ height: 20, marginLeft: -5, filter: 'invert(1)', marginRight: 5 }} />LAST WEEK'S WINNER: {leagueWinners[leagueId]}</div>}
                        <div style={{ ...styles.inverseText, ...styles.bold, ...styles.header2, ...styles.marginBottomSmall, marginTop: 20 }}><img alt='ticket' style={{ height: 30, filter: 'invert(1)', marginBottom: -7, marginRight: 5 }} src={ticket} />{title}</div>
                        <div style={{ ...styles.inverseText, ...styles.bold, ...styles.header4, ...styles.marginBottomSmall }}>{LEAGUE_SUBTITLES[leagueId]}</div>
                        <div style={{ ...styles.inverseText, ...styles.bold, ...styles.header1, color: '#00F9EA', ...styles.marginBottomExtra }}>{players / divider} {SYMBOL}</div>
                        {joiningLeagues[leagueId] && <div style={{ ...styles.marginBottomExtra }}>
                            <div style={{ ...styles.inverseText, ...styles.marginBottom }}>Joining league, this may take a minute...</div>
                            <Dots color={'white'} style={{ ...styles.flex, ...styles.centeredContainer }} />
                        </div>
                        }
                        {hasPortfolio && <Link
                            to={`/play/${leagueId}`}
                            className='clickable'
                            style={{ ...styles.marginBottom, ...styles.roundedCorners, ...styles.baseButton, ...(styles.enabledButton), ...styles.fullWidth, ...{ cursor: 'pointer', color: TEXT_COLOR, justifyContent: 'center', display: 'flex', maxWidth: 300, minHeight: 40, textDecoration: 'none' } }}
                        >
                            <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{'PLAY'}</p>
                        </Link>}
                        {notConnected &&
                            <div
                                onClick={() => updateShowConnectModal(true)}
                                className='clickable'
                                style={{ ...styles.marginBottom, ...styles.roundedCorners, ...styles.baseButton, ...(buttonStyles.walletConnectedButton), ...styles.fullWidth, ...{ cursor: 'pointer', justifyContent: 'center', display: 'flex', maxWidth: 300, minHeight: 40, paddingLeft: 0, paddingRight: 0 } }}
                            >
                                <p style={{ ...styles.text, color: 'white', ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{'CONNECT WALLET'}</p>
                            </div>
                        }
                        {!notConnected && !joiningLeagues[leagueId] && <div
                            onClick={() => this.setState({ showModal: true, index: leagueId })}
                            className='clickable'
                            style={{ ...styles.marginBottom, ...styles.roundedCorners, ...styles.baseButton, ...(!hasPortfolio ? styles.enabledButton : { backgroundColor: '#FFFFFF' }), ...styles.fullWidth, ...{ cursor: 'pointer', justifyContent: 'center', display: 'flex', maxWidth: 300, minHeight: 40, paddingLeft: 0, paddingRight: 0 } }}
                        >
                            <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{hasPortfolio ? 'BUY ANOTHER BAG' : 'JOIN'}</p>
                        </div>}
                        {!notConnected && !joiningLeagues[leagueId] && !hasPortfolio && leagueHasPlayers && <Link
                            to={`/play/${leagueId}`}
                            className='clickable'
                            style={{ textDecoration: 'none', ...styles.marginBottom, color: TEXT_COLOR, ...styles.roundedCorners, ...styles.baseButton, backgroundColor: '#FFFFFF', ...styles.fullWidth, ...{ cursor: 'pointer', justifyContent: 'center', display: 'flex', maxWidth: 300, minHeight: 40, paddingLeft: 0, paddingRight: 0 } }}
                        >
                            <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{'VIEW STANDINGS'}</p>
                        </Link>}
                        <div style={{ ...styles.flex, justifyContent: 'space-between', ...styles.paddingExtra, ...styles.inverseText, ...styles.bold, ...styles.header4, ...styles.marginBottomSmall, ...styles.fullWidth }}>
                            <div style={{ ...styles.marginRightExtra }}>
                                <div style={{ textAlign: 'left', ...(isMobile && styles.smallText) }}>Players</div>
                                <div style={{ textAlign: 'left', ...(isMobile && styles.smallText) }}>{players}</div>
                            </div>
                            <div style={{ ...styles.marginRightExtra }}>
                                <div style={{ textAlign: 'left', ...(isMobile && styles.smallText) }}>League Starts</div>
                                <div style={{ textAlign: 'left', ...(isMobile && styles.smallText) }}>9:30 AM Monday</div>
                            </div>
                            <div style={{ ...styles.marginRightExtra }}>
                                <div style={{ textAlign: 'left', ...(isMobile && styles.smallText) }}>League Ends</div>
                                <div style={{ textAlign: 'left', ...(isMobile && styles.smallText) }}>4:00 PM Friday</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render(): any {

        const { league } = this.props;
        const { leaguePlayerCounts } = league || {};

        const { index, showModal } = this.state;

        const date = new Date();
        const convertedDate = convertTZ(date, "America/New_York");
        const isWeekend = convertedDate.getDay() === 6
            || convertedDate.getDay() === 0
            || (convertedDate.getDay() === 5 && convertedDate.getHours() >= 17);

        const modalPrice = isWeekend ? LEAGUE_PRICES[index] : LEAGUE_PRICES[index] + LEAGUE_PRICES[index] * (convertedDate.getDay()) / 5.0;

        const isFlatFee = index === 1;

        return (
            <WrappedAnimatingComponent>
                <div style={{ margin: 'auto', ...styles.fullWidth, flexGrow: 1, ...styles.flex, }}>
                    <div ref={this.scrollContainer} style={{ overflow: 'scroll', ...styles.marginTop, width: '100%', ...styles.flex, flexDirection: 'column', alignItems: 'center', height: REMAINDER_HEIGHT }}>
                        <div style={{ width: '100%', maxWidth: MAX_WIDTH, ...(isMobileSafari && { paddingBottom: 60 }) }}>
                            {LEAGUE_IDS.map(id => this.getLeague(LEAGUE_TITLES[id], leaguePlayerCounts[id] || 0, LEAGUE_PRICES[id], null, id, id >= LEAGUE_IDS.length - 1))}
                        </div>
                    </div>
                    <ModalComponent showModal={showModal} onRequestClose={() => this.setState({ showModal: false })}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{ ...styles.inverseText, ...styles.header3, ...styles.bold, ...styles.centerText }}><img alt='ticket' style={{ height: 30, filter: 'invert(1)', marginBottom: -7, marginRight: 5 }} src={ticket} />{LEAGUE_TITLES[index]}</p>
                            <div style={{ ...styles.inverseText, ...styles.bold, ...styles.header1, color: '#00F9EA' }}>JOIN LEAGUE?</div>

                            <div style={{ ...styles.inverseText, ...styles.bold, ...styles.marginBottom }}>YOU WILL BE CHARGED {isFlatFee ? 1.1 : modalPrice.toFixed(!index ? 1 : 0)} {SYMBOL}</div>
                            <div
                                onClick={this.onJoinLeague}
                                className='clickable'
                                style={{ ...styles.marginBottomExtra, ...styles.roundedCorners, ...styles.baseButton, ...(styles.enabledButton), ...styles.fullWidth, ...{ cursor: 'pointer', justifyContent: 'center', display: 'flex', maxWidth: 300, minHeight: 40 } }}
                            >
                                <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{'JOIN'}</p>
                            </div>
                            <div style={{ paddingLeft: BOX_PADDING, paddingRight: BOX_PADDING, ...styles.flexColumnCentered }}>
                                <div style={{ ...styles.inverseText, ...styles.bold, ...styles.header3, ...styles.marginBottom }}>GAME RULES</div>
                                <div style={{ ...styles.inverseText, ...styles.bold, ...styles.marginBottom, ...styles.smallText, ...styles.centerText }}>Players get a bag of 100 bananas to start the game. One banana can be traded for one of any stock share. Because all bananas were created equal, raw prices don’t matter. All that matters is gains. Build a portfolio and trade your bananas to generate returns. {isFlatFee ? 'The top 50% of players will win 2 MATIC' : 'The player with the most bananas in any bag at the end of the game wins'}.</div>
                                {!isMobile && <> <div style={{ ...styles.inverseText, ...styles.bold, ...styles.marginBottom, ...styles.smallText }}>TRADING HOURS: 9:30AM - 4PM ET | MON - FRI</div>
                                    {!isFlatFee && <div style={{ ...styles.inverseText, ...styles.bold, ...styles.marginBottom, ...styles.smallText }}>BUY-IN: {LEAGUE_PRICES[index]} {SYMBOL} BEFORE MONDAY</div>}
                                    {isFlatFee && <div style={{ ...styles.inverseText, ...styles.bold, ...styles.marginBottom, ...styles.smallText }}>BUY-IN: {LEAGUE_PRICES[index] * 1.1} {SYMBOL}</div>}
                                    {!isFlatFee && <div style={{ ...styles.inverseText, ...styles.bold, ...styles.marginBottom, ...styles.smallText }}>WAITING PREMIUM: {LEAGUE_PRICES[index] / 5} {SYMBOL} PER DAY</div>}
                                    <div style={{ ...styles.inverseText, ...styles.bold, ...styles.marginBottom, ...styles.smallText }}>STYLE: 50/50 | TOP 50% OF PLAYERS WIN</div>
                                    <div style={{ ...styles.inverseText, ...styles.bold, ...styles.marginBottom, ...styles.smallText }}>ASSETS: SNOWDEX100 STOCKS</div>
                                    <div style={{ ...styles.inverseText, ...styles.bold, ...styles.marginBottom, ...styles.smallText }}>PORTFOLIOS: ONE PER BUY-IN | NO LIMIT</div>
                                </>}
                            </div>
                        </div>
                    </ModalComponent>
                </div>
            </WrappedAnimatingComponent>
        );
    }
}

function convertTZ(date: Date, tzString: string) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
}

const mapStateToProps = (state: GlobalState) => {
    return {
        auth: state.auth,
        league: state.league,
    };
};

export default connect(mapStateToProps, { updatePortfolios, updateShowConnectModal })(Leagues)