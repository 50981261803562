import { BACKGROUND_COLOR, BORDER_COLOR, BORDER_RADIUS, DULL_TEXT_COLOR, DULL_TEXT_COLOR_DARKER, LIGHT_BACKGROUND_COLOR, PRIMARY_BUTTON_BACKGROUND_COLOR, RED } from './constants';

export const styles = {
    container: {
        backgroundColor: BACKGROUND_COLOR,
        alignItems: 'center',
        justifyContent: 'center',
    },
    defaultBackgroundColor: {
        backgroundColor: BACKGROUND_COLOR,
    },
    lightBackgroundColor: {
        backgroundColor: LIGHT_BACKGROUND_COLOR,
    },
    transparentBackgroundColor: {
        backgroundColor: 'transparent',
    },
    flex: {
        display: 'flex' as 'flex',
    },
    flexGrow: {
        flexGrow: 1
    },
    flexShrink: {
        flexShrink: 1
    },
    flexRow: {
        flexDirection: 'row' as 'row',
    },
    flexRowSpaceBetween: {
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
    },
    flexColumnCentered: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center'
    },
    centerJustified: {
        justifyContent: 'center'
    },
    centerAligned: {
        alignItems: 'center'
    },
    endAligned: {
        alignItems: 'flex-end'
    },
    autoMargin: {
        margin: 'auto'
    },
    fullWidth: {
        width: '100%'
    },
    fullHeight: {
        height: '100%'
    },
    horizontallyCenteredContainer: {
        alignItems: 'center',
    },
    horizontallyPaddedContainer: {
        paddingHorizontal: 10,
    },
    centeredContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    inverseText: {
        color: BACKGROUND_COLOR,
    },
    landingPage: {
        fontSize: 70,
    },
    header1: {
        fontSize: 30,
    },
    header2: {
        fontSize: 25,
    },
    header3: {
        fontSize: 20,
    },
    header4: {
        fontSize: 15,
    },
    smallText: {
        fontSize: 12
    },
    text: {
    },
    bold: {
        fontWeight: 'bold',
    },
    underline: {
        textDecorationLine: 'underline'
    },
    centerText: {
        textAlign: 'center' as 'center',
    },
    dullText: {
        color: DULL_TEXT_COLOR,
    },
    dullTextDark: {
        color: DULL_TEXT_COLOR_DARKER,
    },
    redText: {
        color: RED
    },
    moneyText: {
        color: '#7b9a6d'
    },
    borderBottom: {
        borderBottomWidth: 1,
        borderBottomColor: LIGHT_BACKGROUND_COLOR
    },
    padding: {
        padding: 10,
    },
    paddingExtra: {
        padding: 20,
    },
    paddingBottom: {
        paddingBottom: 10,
    },
    marginBottomSmall: {
        marginBottom: 5,
    },
    marginBottom: {
        marginBottom: 10,
    },
    marginBottomExtra: {
        marginBottom: 20,
    },
    marginTop: {
        marginTop: 10,
    },
    marginRight: {
        marginRight: 10,
    },
    marginRightExtra: {
        marginRight: 20,
    },
    roundedCorners: {
        borderRadius: BORDER_RADIUS,
    },
    topCorners: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
    },
    bottomCorners: {
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
    },
    baseButton: {
        height: 40,
        justifyContent: 'center',
        paddingHorizontal: 10,
    },
    disabledButton: {
        backgroundColor: DULL_TEXT_COLOR,
    },
    enabledButton: {
        backgroundColor: PRIMARY_BUTTON_BACKGROUND_COLOR,
    },
    baseTextInput: {
        height: 40,
        paddingHorizontal: 10,
        outlineColor: DULL_TEXT_COLOR,
    },
    textInput: {
        backgroundColor: BACKGROUND_COLOR,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
    },
    emptyTextInput: {
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        color: DULL_TEXT_COLOR,
    },
    shadow: {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
    }
};
