import { combineReducers } from 'redux';
import { filterActions } from 'redux-ignore';

import authReducer from './authReducer';
import { actionTypes } from '../actions/types';
import leagueReducer from './leagueReducer';

export default combineReducers({
    auth: filterActions(authReducer, [
        actionTypes.UPDATE_USER,
        actionTypes.UPDATE_LOCAL_ACCOUNT,
        actionTypes.UPDATE_WEB_3,
        actionTypes.UPDATE_LOCAL_ACCOUNT_AND_WEB_3,
        actionTypes.UPDATE_NETWORK_ID,
        actionTypes.UPDATE_PROFILE,
    ]),
    league: filterActions(leagueReducer, [
        actionTypes.UPDATE_PORTFOLIOS,
        actionTypes.UPDATE_LEAGUES,
        actionTypes.UPDATE_STOCKS,
        actionTypes.UPDATE_SICKEST_APES,
        actionTypes.UPDATE_SHOW_CONNECT_MODAL,
        actionTypes.UPDATE_NONCES,
        actionTypes.UPDATE_WINNERS,
        actionTypes.UPDATE_PLAYER_COUNTS,
    ]),
});
