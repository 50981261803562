import * as React from 'react';
import { Header } from './Header';
import { Navigate, Route, Routes } from 'react-router';
import Leagues from './Leagues';
import Play from './Play';
import Me from './Me';
import SickestApes from './SickestApes';
import MyStuff from './MyStuff';

type Props = {
};

type State = {
};

class Navigation extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }

    render(): any {

        return (
            <>
                <Header />
                <Routes>
                    <Route
                        path="*"
                        element={<Navigate to="/live-games" replace />}
                    />
                    <Route element={<Leagues />} path="/live-games" />
                    <Route element={<MyStuff />} path="/me" />
                    <Route element={<SickestApes />} path="/top-apes" />
                    <Route element={<Play />} path="/play/:id" />
                    <Route element={<Me />} path="/edit-profile" />
                </Routes>
            </>
        );
    }
}

export default Navigation;
