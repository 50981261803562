import React from 'react';

import { styles } from './generalStyles';
import WalletLink from 'walletlink'
import Web3Modal from "web3modal";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Torus from "@toruslabs/torus-embed";
import "react-activity/dist/library.css";
import { Dots } from "react-activity";
import _ from 'lodash';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithCustomToken, signOut } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeAnalytics } from 'firebase/analytics';
import { connect } from 'react-redux';
import { AuthObject, GlobalState, LeagueObject } from './flowTypes';
import { updateLocalAccount, updateLocalAccountAndWeb3, updateNetworkId } from './actions/authActions';
import Navigation from './components/Navigation';
import { BOX_COLOR, CHAIN_EXPLORER, CHAIN_HEX, CHAIN_ID, CHAIN_NAME, DULL_TEXT_COLOR_DARKER, INFURA_URL, MAX_WIDTH, SECONDARY_BUTTON_BACKGROUND_COLOR, SMART_CONTRACT, SMART_CONTRACT_ADDRESS, SYMBOL, TEXT_COLOR } from './constants';
import ModalComponent from './components/ModalComponent';
import { updateShowConnectModal } from './actions/leagueActions';
import { lightBorderNoPaddingOnTopLessPadding } from './UI Resources';

const firebaseConfig = {
    apiKey: "AIzaSyC5lvvfdByH7RE762J2suGjD_iOXI2ZxeI",
    authDomain: "snow-ape.firebaseapp.com",
    databaseURL: "https://snow-ape-default-rtdb.firebaseio.com",
    projectId: "snow-ape",
    storageBucket: "snow-ape.appspot.com",
    messagingSenderId: "573290133686",
    appId: "1:573290133686:web:74abbdfa18ae54aceb0e17",
    measurementId: "G-LE4PS1HS3R"
};

const app = initializeApp(firebaseConfig);
initializeAnalytics(app);

const functions = getFunctions(app);
const auth = getAuth(app);

const contract = SMART_CONTRACT;

const contractAddress = SMART_CONTRACT_ADDRESS;;
const web3Modal = new Web3Modal({
    network: 'polygon', // optional
    cacheProvider: true, // optional,
    theme: {
        background: BOX_COLOR,
        main: "rgb(199, 199, 199)",
        secondary: "rgb(136, 136, 136)",
        border: "rgba(195, 195, 195, 0.0)",
        hover: "rgb(18, 18, 18)",
    },
    providerOptions: {
        walletlink: {
            package: WalletLink, // Required
            options: {
                appName: 'Snow Ape',
                rpc: INFURA_URL,
                chainId: CHAIN_ID, // Optional. It defaults to 1 if not provided,
                darkMode: true,
            }
        },
        walletconnect: {
            package: WalletConnectProvider, // required
            options: {
                rpc: {
                    [CHAIN_ID]: INFURA_URL,
                },
                network: 'matic',
            }
        },
        torus: {
            package: Torus, // required
            options: {
                networkParams: {
                    host: INFURA_URL, // optional
                    chainId: CHAIN_ID, // optional
                    networkId: CHAIN_ID // optional
                },
            }
        }

    }
});

type Props = {
    auth: AuthObject,
    league: LeagueObject,
    updateLocalAccount: typeof updateLocalAccount,
    updateLocalAccountAndWeb3: typeof updateLocalAccountAndWeb3,
    updateNetworkId: typeof updateNetworkId,
    updateShowConnectModal: typeof updateShowConnectModal,
};

type State = {
    ticketCount: number,
    ticketIds: string[],
    poolSize: number,
    localAccount: string,
    localTransactionState: string,
    nftContract?: any,
    componentIsReady: boolean,
    winningTicketId: number,
    connecting: boolean,
    signingIn: boolean,
    switchingNetwork: boolean,
};
export class ConnectWallet extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            ticketCount: 0,
            ticketIds: [],
            poolSize: 0,
            localAccount: '',
            localTransactionState: 'Success',
            componentIsReady: false,
            winningTicketId: 0,
            connecting: false,
            signingIn: false,
            switchingNetwork: false,
        }
    }

    componentDidMount() {

        if (window.localStorage.getItem('hasBeenOnWebsite')) {
            this.handleConnectWallet();
        }

        setTimeout(() => {
            this.setState({ componentIsReady: true });
        }, 100);

        onAuthStateChanged(auth, () => {
            this.setState({ signingIn: false });
        });
    }

    handleConnectWallet = async () => {
        const {
            updateLocalAccount,
            updateLocalAccountAndWeb3,
            updateNetworkId,
            updateShowConnectModal
        } = this.props;
        this.setState({ connecting: true });
        let provider;
        try {
            provider = await web3Modal.connect();
        } catch (e) {
            this.setState({ connecting: false });
            return;
        } finally {
            this.setState({ connecting: false });
        }

        const web3 = new Web3(provider);
        const nftContract = new web3.eth.Contract(contract.abi, contractAddress);

        const chainId = await web3?.eth.getChainId();
        const accounts = await web3.eth.getAccounts();
        if (!accounts.length) {
            return;
        }

        updateNetworkId(chainId);
        updateLocalAccountAndWeb3(web3.utils.toChecksumAddress(accounts[0]), web3);

        this.setState({ nftContract });

        setInterval(async () => {
            const { auth: authProps } = this.props;
            const { networkId, uid, localAccount } = authProps || {};
            const newNetworkId = await web3?.eth.getChainId();
            if (networkId !== newNetworkId) {
                updateNetworkId(newNetworkId);
            }
            updateShowConnectModal(!uid || (uid !== localAccount) || (Boolean(newNetworkId) && newNetworkId !== CHAIN_ID));
        }, 500);

        setInterval(async () => {
            const { auth: authProps } = this.props;
            const { localAccount } = authProps || {};

            const accounts = await web3.eth.getAccounts();
            if (localAccount === accounts[0] || (!localAccount && !accounts.length)) {
                return;
            }

            signOut(auth);
            if (!accounts.length) {
                updateLocalAccount('');
                return;
            }

            updateLocalAccount(web3.utils.toChecksumAddress(accounts[0]));
        }, 500);
    }

    switchChain = async () => {
        const { auth } = this.props;
        const { web3 } = auth || {};

        this.setState({ switchingNetwork: true });
        try {
            // @ts-ignore
            await web3.eth.currentProvider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: CHAIN_HEX }], // chainId must be in hexadecimal numbers
            });
        } catch (e: any) {
            if (e) {
                // @ts-ignore
                await web3.eth.currentProvider.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            chainId: CHAIN_HEX,
                            chainName: CHAIN_NAME,
                            rpcUrls: ['https://polygon-rpc.com'],
                            nativeCurrency: {
                                name: "Matic",
                                symbol: SYMBOL,
                                decimals: 18,
                            },
                            blockExplorerUrls: [CHAIN_EXPLORER],
                        },
                    ]
                });
            }
        }
        this.setState({ switchingNetwork: false });
    }

    signIn = async () => {
        const { auth: authProps } = this.props;
        const { localAccount, web3 } = authProps || {};
        if (!web3 || !localAccount) return;

        this.setState({ signingIn: true });

        const createAuthPasswordSeed = httpsCallable(functions, 'auth-createAuthPasswordSeed');

        const result = await createAuthPasswordSeed({ address: localAccount }) as { data: string };
        const sha: string = result.data;

        web3.eth.personal.sign(sha, localAccount, 'password', async (e, signature) => {
            if (e) {
                this.setState({ signingIn: false });
                return;
            }

            const createUserWithSignature = httpsCallable(functions, 'auth-createUserWithSignature');
            const result = await createUserWithSignature({
                address: localAccount,
                signature
            }) as { data: { token: string, error?: string } };
            const token = result.data.token;

            if (result.data.error) {
                this.setState({ signingIn: false });
                return;
            };

            signInWithCustomToken(auth, token);
        });
    }

    renderConnection() {
        const {
            componentIsReady,
            connecting,
            signingIn,
            switchingNetwork,
        } = this.state;

        const { auth } = this.props;
        const { localAccount, networkId, uid } = auth || {};

        const onCorrectNetwork = networkId === CHAIN_ID;

        const accountCorrect = Boolean(localAccount && (localAccount === uid));

        return (
            <>
                <div style={{ flexGrow: 1, display: 'flex', width: '100%', maxWidth: MAX_WIDTH, flexDirection: 'column' }}>
                    <div style={{ ...styles.marginBottomExtra, ...styles.header3, ...styles.marginBottom, ...styles.inverseText }}><b>{`CONNECT WALLET`}</b></div>
                    {lightBorderNoPaddingOnTopLessPadding()}
                    {componentIsReady && <div style={{ ...styles.centeredContainer, height: '100%', ...styles.flex, flexDirection: 'column', width: '100%', ...styles.paddingExtra, paddingTop: 0, paddingBottom: 0 }}>
                        <div style={{ ...styles.inverseText, ...styles.marginBottomExtra, ...styles.centerText, ...styles.bold }}>Connect your wallet, switch to Polygon, and sign in.</div>
                        <div
                            className='clickable'
                            onClick={!localAccount ? this.handleConnectWallet : _.noop}
                            style={{ ...styles.marginBottom, ...styles.roundedCorners, ...styles.baseButton, ...(!localAccount ? { backgroundColor: SECONDARY_BUTTON_BACKGROUND_COLOR } : { color: DULL_TEXT_COLOR_DARKER, border: `2px solid ${DULL_TEXT_COLOR_DARKER}` }), ...styles.fullWidth, ...{ cursor: 'pointer', justifyContent: 'center', display: 'flex', maxWidth: 300 } }}
                        >
                            {!connecting ?
                                <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{localAccount ? 'WALLET CONNECTED ✅' : 'CONNECT WALLET'}</p> :
                                <Dots color={TEXT_COLOR} style={{ ...styles.flex, ...styles.centeredContainer }} />
                            }
                        </div>
                        <div
                            className='clickable'
                            onClick={this.switchChain}
                            style={{ ...styles.marginBottom, ...styles.roundedCorners, ...styles.baseButton, ...((localAccount && !onCorrectNetwork) ? { backgroundColor: SECONDARY_BUTTON_BACKGROUND_COLOR } : { color: DULL_TEXT_COLOR_DARKER, border: `2px solid ${DULL_TEXT_COLOR_DARKER}` }), ...styles.fullWidth, ...{ cursor: 'pointer', justifyContent: 'center', display: 'flex', maxWidth: 300 } }}
                        >
                            {!switchingNetwork ?
                                <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{onCorrectNetwork ? 'CONNECTED TO POLYGON ✅' : 'CONNECT TO POLYGON'}</p> :
                                <Dots color={TEXT_COLOR} style={{ ...styles.flex, ...styles.centeredContainer }} />
                            }
                        </div>
                        <div
                            className='clickable'
                            onClick={!accountCorrect ? this.signIn : _.noop}
                            style={{ ...styles.roundedCorners, ...styles.baseButton, ...((!accountCorrect && onCorrectNetwork) ? { backgroundColor: SECONDARY_BUTTON_BACKGROUND_COLOR } : { color: DULL_TEXT_COLOR_DARKER, border: `2px solid ${DULL_TEXT_COLOR_DARKER}` }), ...styles.fullWidth, ...{ cursor: 'pointer', justifyContent: 'center', display: 'flex', maxWidth: 300 } }}
                        >
                            {!signingIn ?
                                <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{accountCorrect ? 'SIGNED IN ✅' : 'SIGN IN'}</p> :
                                <Dots color={TEXT_COLOR} style={{ ...styles.flex, ...styles.centeredContainer }} />
                            }
                        </div>
                    </div>}
                </div >
            </>
        );
    }

    render() {
        const {
            league,
            updateShowConnectModal
        } = this.props;

        const { showConnectModal } = league || {};

        // if (!localAccount || !Boolean(uid) || localAccount !== uid || !onCorrectNetwork) return this.renderConnection();

        return (
            <>
                <ModalComponent showModal={showConnectModal} onRequestClose={() => updateShowConnectModal(false)}>
                    {this.renderConnection()}
                </ModalComponent>
                <Navigation />
            </>
        );
    }
}

const mapStateToProps = (state: GlobalState) => {
    return {
        auth: state.auth,
        league: state.league
    };
};

export default connect(mapStateToProps, { updateLocalAccount, updateLocalAccountAndWeb3, updateNetworkId, updateShowConnectModal })(ConnectWallet)