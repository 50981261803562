import * as React from 'react';

import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { DULL_TEXT_COLOR_DARKER, MAX_WIDTH } from '../../constants';
import { GlobalState } from '../../flowTypes';
import { buttonStyles } from './buttonStyles';

export const Header = () => {

    const location = useLocation();
    const pathname = location.pathname;
    const isOngames = pathname === '/live-games';
    const isOnBags = pathname === '/me';
    const isOnApes = pathname === '/top-apes';

    return (
        <div style={{ ...buttonStyles.navigationToggleContainer, maxWidth: MAX_WIDTH }}>
            <Link
                style={{ ...buttonStyles.navigationItem, ...(isOngames ? buttonStyles.navigationItemSelected : { color: DULL_TEXT_COLOR_DARKER }) }}
                to="/live-games"
            >
                LIVE GAMES
            </Link>
            <Link
                style={{ ...buttonStyles.navigationItem, ...(isOnBags ? buttonStyles.navigationItemSelected : { color: DULL_TEXT_COLOR_DARKER }) }}
                to="/me"
            >
                MY STUFF
            </Link>
            <Link
                style={{ ...buttonStyles.navigationItem, ...(isOnApes ? buttonStyles.navigationItemSelected : { color: DULL_TEXT_COLOR_DARKER }) }}
                to="/top-apes"
            >
                TOP APES
            </Link>
        </div>
    );
}

const mapStateToProps = (state: GlobalState) => {
    return {
        auth: state.auth
    };
};

const connected: any = connect(mapStateToProps, {})((Header));
export default connected;
