import * as React from 'react';
import { buttonStyles } from './buttonStyles';
import '../../index.css';
import _ from 'lodash';
import { Asset, AuthObject, GlobalState, LeagueObject, Portfolio, Stock } from '../../flowTypes';
import { connect } from 'react-redux';
import { styles } from '../../generalStyles';
import { lightBorderLessPadding } from '../../UI Resources';
import { getFunctions, httpsCallable } from 'firebase/functions';
import BuyTicker from './BuyTicker';
import SellTicker from './SellTicker';
import { Dots } from 'react-activity';
import { BOX_PADDING, DULL_TEXT_COLOR_DARKER, GREEN, RED, TEXT_COLOR } from '../../constants';
import ModalComponent from '../ModalComponent';
import TradingViewWidget from '../../TradingViewWidget';
import { MechanicalCounter } from "mechanical-counter";
import { FixedSizeList as List } from "react-window";
import AutoSizer from 'react-virtualized-auto-sizer';

import banana from '../../assets/banana.png';
import { isMobile } from 'react-device-detect';


const buttonWidth = isMobile ? 60 : 80;

type Props = {
    auth: AuthObject,
    league: LeagueObject,
    portfolio?: Portfolio,
    portfolioIndex: number,
    leagueId: number,
    marketClosed: boolean,
};

type State = {
    buying: boolean,
    filter: string,
    tickerToBuy: string,
    tickerToSell: Asset | undefined,
    tickerToSellOwned: number,
    loadingIndicator: Asset | undefined,
    buyLoadingIndicator: string,
    tickerToPreview: string,
    stocks: Stock[],
};

class TradeModal extends React.Component<Props, State> {


    scroll: React.RefObject<HTMLDivElement>
    buy: React.RefObject<HTMLDivElement>
    sell: React.RefObject<HTMLDivElement>
    buyList: any

    constructor(props: Props) {
        super(props);

        this.scroll = React.createRef();
        this.buy = React.createRef()
        this.sell = React.createRef();

        this.buyList = React.createRef();

        this.state = {
            buying: true,
            filter: '',
            tickerToBuy: '',
            tickerToSell: undefined,
            tickerToSellOwned: 1,
            loadingIndicator: undefined,
            buyLoadingIndicator: '',
            tickerToPreview: '',
            stocks: [],
        };
    }

    componentDidUpdate(oldProps: Props, oldState: State) {
        const { portfolio, league, leagueId } = this.props;
        const { stocks, nonces } = league || {};
        const { filter } = this.state;

        if (!_.isEqual(stocks, oldProps.league?.stocks) || !_.isEqual(filter, oldState.filter)) {
            this.setState({ stocks: filter ? stocks.filter(s => s.ticker.includes(filter.toLocaleUpperCase())) : stocks });
        }

        if (!oldProps.portfolio && portfolio) {
            setTimeout(() => {
                this.setIfBuying(!Boolean(portfolio.assets?.length));
            }, 0);
        }

        if (!_.isEqual(portfolio, oldProps.portfolio)) {
            // this.buyList.current.resetAfterIndex(0);
            this.setState({ loadingIndicator: undefined, buyLoadingIndicator: '' });
        }

        if (portfolio?.assets?.length) {
            window.localStorage.setItem(`showSell-${nonces[leagueId]}-${leagueId}`, 'yes');
        } else {
            window.localStorage.removeItem(`showSell-${nonces[leagueId]}-${leagueId}`);
        }
    }

    componentDidMount() {
        const { portfolio, league, leagueId } = this.props;
        const { stocks, nonces } = league || {};

        this.setState({ stocks });

        if (window.localStorage.getItem(`showSell-${nonces[leagueId]}-${leagueId}`) || portfolio?.assets?.length) {
            this.setIfBuying(false);
        }
    }

    setIfBuying(buying: boolean) {

        this.setState({ buying });
        if (buying) {
            this.scroll.current?.scrollTo({ left: 0 });
        } else {
            this.scroll.current?.scrollTo({ left: this.scroll.current.getBoundingClientRect().width });
        }
    }

    buyStock = (ticker: string, quantity: number) => {
        const { marketClosed } = this.props;
        if (marketClosed) {
            return;
        }
        const { leagueId, portfolioIndex } = this.props;
        const functions = getFunctions();
        const buyStock = httpsCallable(functions, 'leagues-buyStock');

        buyStock({ portfolioIndex, leagueId, ticker, quantity });
        this.setState({ buyLoadingIndicator: ticker });
    }

    sellStock = (asset: Asset | undefined, quantity: number) => {
        const { marketClosed } = this.props;

        if (!asset) return;

        if (marketClosed) {
            return;
        }

        const { leagueId, portfolioIndex } = this.props;
        const functions = getFunctions();
        const sellStock = httpsCallable(functions, 'leagues-sellStock');

        sellStock({ portfolioIndex, leagueId, ticker: asset.ticker, quantity, price: asset.price });
        this.setState({ loadingIndicator: asset });
    }

    setFilter = (filter: string) => {
        const { league } = this.props;
        const { stocks } = league || {};
        this.setState({ filter, stocks: (stocks || []).filter(s => s.ticker.includes(filter.toLocaleUpperCase())) });
    }

    getBuyRow = (props: { index: number, style: any }) => {
        const { auth, marketClosed } = this.props;
        const { uid } = auth || {};
        const { stocks, buyLoadingIndicator } = this.state;
        const stock = stocks[props.index];
        if (!stock) return null;
        return (
            <div key={`buyrow-${props.index}`} className='row' style={{ ...props.style }}>
                <div style={{ ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.centerAligned }}>
                    <div style={{}}>
                        <div style={{ ...styles.inverseText, ...styles.bold }}>{stock.ticker}</div>
                        <div style={{ ...styles.inverseText, ...styles.dullText, fontSize: 12 }}>{stock.name}</div>
                    </div>
                    <div style={{ ...styles.flex, ...styles.flexRow, ...styles.centerAligned }}>
                        <div className='clickable' style={{ width: 80, cursor: 'pointer', ...styles.marginRight }} onClick={() => this.setState({ tickerToPreview: stock.ticker })}>
                            <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: "center", ...styles.bold, ...styles.inverseText }}>
                                $<MechanicalCounter text={`${stock.price.toFixed(2).split('.')[0]}`} />.<MechanicalCounter text={`${stock.price.toFixed(2).split('.')[1]}`} />
                            </div>
                            <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: "center", ...styles.smallText, ...styles.bold, color: stock.changePercent.includes('-') ? RED : GREEN }}>
                                {stock.changePercent.includes('-') ? '' : '+'}<MechanicalCounter text={stock.changePercent.split('.')[0]} />.<MechanicalCounter text={stock.changePercent.split('.')[1]} />
                            </div>
                        </div>
                        <div style={{ width: buttonWidth }}>
                            <div
                                onClick={uid ? () => { !marketClosed && this.setState({ tickerToBuy: stock.ticker }); } : _.noop}
                                className='clickable'
                                style={{ ...(marketClosed && { filter: 'grayscale(1)', border: `1px solid ${DULL_TEXT_COLOR_DARKER}` }), ...styles.roundedCorners, ...styles.baseButton, backgroundColor: marketClosed ? 'transparent' : '#00F9EA', ...styles.fullWidth, ...{ cursor: 'pointer', display: 'flex', height: 35, alignSelf: 'center' } }}
                            >
                                {buyLoadingIndicator === stock.ticker ?
                                    <Dots color={TEXT_COLOR} style={{ ...styles.flex, ...styles.centeredContainer }} /> :
                                    <p style={{ ...styles.text, ...(marketClosed ? styles.dullTextDark : {}), ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{'BUY'}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {lightBorderLessPadding()}
            </div>
        )
    }

    getSellRow = (props: { index: number, style: any }) => {

        const { auth, league, marketClosed, portfolio } = this.props;
        const { uid } = auth || {};
        const { stocks } = league || {};

        const asset = (portfolio?.assets || [])[props.index];
        const stock = stocks.find(s => s.ticker === asset.ticker);

        if (!asset || !stock) return null;

        const { loadingIndicator } = this.state;

        const stockPrice = stock?.price || 0;
        const change = ((stockPrice - asset.price) / asset.price) * 100;
        const bananas = ((stockPrice - asset.price) / asset.price + 1) * asset.quantity;

        return (
            <div key={`sellrow-${props.index}`} className='row' style={{ ...props.style }}>
                <div style={{ ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.centerAligned }}>
                    <div style={{}}>
                        <div style={{ ...styles.inverseText, ...styles.bold }}>{stock.ticker}</div>
                        <div style={{ ...styles.inverseText, ...styles.dullText, fontSize: 12 }}>{stock.name}</div>
                        <div style={{ ...styles.dullText, fontSize: 12, ...styles.flex }}>Bought for:<div style={{ marginLeft: 5 }}>${asset.price.toFixed(2)}</div></div>
                        <div style={{ ...styles.dullText, fontSize: 12, ...styles.flex }}>Currently:<div style={{ marginLeft: 12.5 }}>${stockPrice.toFixed(2)}</div></div>
                    </div>
                    <div style={{ ...styles.flex, ...styles.flexRow, ...styles.centerAligned }}>
                        <div style={{ width: 100, cursor: 'pointer', ...styles.marginRight }} onClick={() => this.setState({ tickerToPreview: `${asset.ticker}` })}>
                            <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: "center", ...styles.bold, ...styles.inverseText }}>
                                <img alt='banana' src={banana} style={{ height: 20, ...styles.marginRight }} />
                                <MechanicalCounter text={`${bananas.toFixed(2).split('.')[0]}`} />.<MechanicalCounter text={`${bananas.toFixed(2).split('.')[1]}`} />
                            </div>
                            <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: "center", ...styles.smallText, ...styles.bold, color: change < 0 ? RED : GREEN }}>
                                {change < 0 ? '' : '+'}<MechanicalCounter text={change.toFixed(2).split('.')[0]} />.<MechanicalCounter text={change.toFixed(2).split('.')[1]} />%
                            </div>
                        </div>
                        <div style={{ width: buttonWidth }}>
                            <div
                                onClick={(uid) ? () => { !marketClosed && this.setState({ tickerToSell: asset, tickerToSellOwned: asset.quantity }); } : _.noop}
                                className='clickable'
                                style={{ ...(marketClosed && { filter: 'grayscale(1)', border: `1px solid ${DULL_TEXT_COLOR_DARKER}` }), ...styles.roundedCorners, ...styles.baseButton, backgroundColor: marketClosed ? 'transparent' : '#FFFFFF', ...styles.fullWidth, ...{ cursor: 'pointer', display: 'flex', height: 35, alignSelf: 'center' } }}
                            >
                                {_.isEqual(loadingIndicator, asset) ?
                                    <Dots color={TEXT_COLOR} style={{ ...styles.flex, ...styles.centeredContainer }} /> :
                                    <p style={{ ...styles.text, ...(marketClosed ? styles.dullTextDark : {}), ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{'SELL'}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {lightBorderLessPadding()}
            </div>
        )
    }

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
        }
    }

    render(): any {

        const { portfolio, marketClosed } = this.props;
        const {
            buying,
            tickerToBuy,
            tickerToSell,
            tickerToSellOwned,
            tickerToPreview,
            stocks,
            filter
        } = this.state;

        const totalBananas = portfolio?.totalBananas ?? 100;

        const assets = (portfolio?.assets || []).filter(a => a.ticker.includes(filter.toLocaleUpperCase()));

        return (
            <div style={{ ...styles.flex, flexDirection: 'column', ...styles.fullWidth, ...styles.flexGrow, ...(isMobile && { height: 315 }), paddingRight: BOX_PADDING, paddingLeft: BOX_PADDING }}>
                <div style={{ ...styles.flexRowSpaceBetween, ...styles.flex, ...styles.marginBottom }}>
                    <div
                        className='no-select'
                        onClick={() => { this.setState({ buying: true, filter: '', }); this.scroll.current?.scrollTo({ left: 0 }); }}
                        style={{ cursor: 'pointer', ...styles.marginRight, fontStyle: 'italic', ...buttonStyles.navigationItem, ...(buying ? styles.enabledButton : { color: DULL_TEXT_COLOR_DARKER, border: `2px solid ${DULL_TEXT_COLOR_DARKER}` }) }}
                    >
                        BUY
                    </div>
                    <div
                        className='no-select'
                        onClick={() => { this.setState({ buying: false, filter: '' }); this.scroll.current?.scrollTo({ left: this.scroll.current.getBoundingClientRect().width }); }}
                        style={{ cursor: 'pointer', fontStyle: 'italic', ...buttonStyles.navigationItem, ...(!buying ? { backgroundColor: '#FFFFFF' } : { color: DULL_TEXT_COLOR_DARKER, border: `2px solid ${DULL_TEXT_COLOR_DARKER}` }) }}
                    >
                        SELL
                    </div>
                </div>
                <div ref={this.scroll} style={{ ...styles.flex, flexDirection: 'row', overflow: 'hidden', ...styles.flexGrow }}>
                    <div ref={this.buy} style={{ flexGrow: 1, minWidth: '100%', ...styles.flex, flexDirection: 'column' }}>
                        <div style={{ ...styles.fullWidth, ...styles.marginBottomExtra, ...styles.flex }}>
                            <input type={'search'} enterKeyHint='done' onKeyPress={this.handleKeyPress} style={{ minHeight: 20, ...styles.fullWidth, ...styles.header4, backgroundColor: 'transparent', borderBottom: `2px solid ${DULL_TEXT_COLOR_DARKER}`, borderWidth: '0px 0px 1px 0px', fontStyle: 'italic', ...styles.inverseText, padding: 10, ...(isMobile && { fontSize: 16 }) }} placeholder='Search' value={filter} onChange={(e) => this.setFilter(e.target.value)}></input>
                        </div>
                        <div style={{ ...styles.inverseText, ...styles.bold, ...styles.marginBottomExtra, ...styles.flex, ...styles.flexRowSpaceBetween }}>
                            BANANA RESERVES
                            <span style={{ ...styles.flex, ...styles.centerAligned }}>
                                <img alt='banana' src={banana} style={{ height: 20, ...styles.marginRight }} />
                                <MechanicalCounter text={`${totalBananas.toFixed(2).split('.')[0]}`} />.<MechanicalCounter text={`${totalBananas.toFixed(2).split('.')[1]}`} />
                                <div style={{ width: buttonWidth, marginLeft: 10 }}>
                                </div>
                            </span>
                        </div>
                        <div style={{ ...styles.flexGrow }}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        className="List"
                                        height={height}
                                        itemCount={stocks.length}
                                        itemSize={80.5}
                                        itemData={[...stocks, marketClosed]}
                                        width={width}
                                    >
                                        {this.getBuyRow}
                                    </List>
                                )}
                            </AutoSizer>
                        </div>
                    </div>
                    <div ref={this.sell} style={{ flexGrow: 1, minWidth: '100%', ...styles.flex, flexDirection: 'column' }}>
                        <div style={{ ...styles.fullWidth, ...styles.marginBottomExtra, ...styles.flex }}>
                            <input type={'search'} enterKeyHint='done' onKeyPress={this.handleKeyPress} style={{ minHeight: 20, ...styles.fullWidth, ...styles.header4, backgroundColor: 'transparent', border: `2px solid ${DULL_TEXT_COLOR_DARKER}`, borderWidth: '0px 0px 1px 0px', ...styles.inverseText, fontStyle: 'italic', padding: 10, ...(isMobile && { fontSize: 16 }) }} placeholder='Search' value={filter} onChange={(e) => this.setFilter(e.target.value)}></input>
                        </div>
                        <div style={{ ...styles.inverseText, ...styles.bold, ...styles.marginBottomExtra, ...styles.flex, ...styles.flexRowSpaceBetween }}>
                            BANANA RESERVES
                            <span style={{ ...styles.flex, ...styles.centerAligned }}>
                                <img alt='banana' src={banana} style={{ height: 20, ...styles.marginRight }} />
                                <MechanicalCounter text={`${totalBananas.toFixed(2).split('.')[0]}`} />.<MechanicalCounter text={`${totalBananas.toFixed(2).split('.')[1]}`} />
                                <div style={{ width: buttonWidth, marginLeft: 10 }} />
                            </span>
                        </div>
                        <div style={{ ...styles.flexGrow }}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        className="List"
                                        height={height}
                                        itemCount={assets.length}
                                        itemSize={101.5}
                                        width={width}
                                        itemData={[...assets, ...stocks, marketClosed]}
                                    >
                                        {this.getSellRow}
                                    </List>
                                )}
                            </AutoSizer>
                        </div>
                    </div>
                </div>
                <BuyTicker max={portfolio?.totalBananas || 0} buyTicker={(quantity) => this.buyStock(tickerToBuy, quantity)} showModal={Boolean(tickerToBuy)} ticker={tickerToBuy} onRequestClose={() => this.setState({ tickerToBuy: '' })} />
                <SellTicker max={tickerToSellOwned} sellTicker={(quantity) => this.sellStock(tickerToSell, quantity)} showModal={Boolean(tickerToSell)} ticker={tickerToSell} onRequestClose={() => this.setState({ tickerToSell: undefined, tickerToSellOwned: 1 })} />
                <ModalComponent showModal={Boolean(tickerToPreview)} onRequestClose={() => this.setState({ tickerToPreview: '' })}>
                    <div style={{ ...styles.marginBottomExtra, ...styles.header3, ...styles.marginBottom, ...styles.inverseText }}><b>{`${tickerToPreview} CHART`}</b></div>
                    {lightBorderLessPadding()}
                    <TradingViewWidget symbol={tickerToPreview} theme='Dark' width={'100%'} height={300} interval={30} />
                </ModalComponent>
            </div>
        );
    }
}

const mapStateToProps = (state: GlobalState) => {
    return {
        auth: state.auth,
        league: state.league
    };
};

export default connect(mapStateToProps, {})(TradeModal)