import * as React from 'react';


export const lightBorderLessPadding = (key?: string) => <div key={key} style={{ width: '100%', height: .5, backgroundColor: '#333333', marginTop: 20, marginBottom: 20 }} />;
export const lightBorderNoPaddingOnTop = () => <div style={{ width: '100%', height: .5, backgroundColor: '#333333', marginBottom: 50 }} />;
export const lightBorderNoPaddingOnTopLessPadding = () => <div style={{ width: '100%', height: .5, backgroundColor: '#333333', marginBottom: 20 }} />;


export const getSuffix = (place: number) => {
    switch (place % 10) {
        case 1: return place % 100 === 11 ? "th" : "st";
        case 2: return place % 100 === 12 ? "th" : "nd";
        case 3: return place % 100 === 13 ? "th" : "rd";
        default: return "th";
    }
}

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const getIsAfterHours = () => {
    const d = new Date();
    const est = new Date(d.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    const afterHours = (
        est.getDay() === 0 ||
        est.getDay() === 6 ||
        est.getHours() < 9 ||
        est.getHours() >= 16 ||
        (est.getHours() === 9 && est.getMinutes() < 31)
    );

    return afterHours;
}

const getTimezoneOffset = (timeZone: string, date = new Date()) => {
    const tz = date.toLocaleString("en", { timeZone, timeStyle: "long" }).split(" ").slice(-1)[0];
    const dateString = date.toString();
    const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);

    return offset / 1000 / 60 * -1;
}

export function getIsDecidingTime() {
    // Code to check that date and dayOfWeek are valid left as an exercise ;)

    const resultDate = new Date();
    const est = new Date(resultDate.toLocaleString('en-US', { timeZone: 'America/New_York' }));

    return est.getDay() === 5 && est.getHours() === 16 && est.getMinutes() <= 30;
}

function addHours(numOfHours: number, date = new Date()) {
    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
    return date;
}

export function getLeagueEndDateAndTime() {
    const resultDate = new Date();
    const diff = (getTimezoneOffset('America/New_York') - resultDate.getTimezoneOffset()) / 60;
    resultDate.setDate(resultDate.getDate() + (7 + 5 - resultDate.getDay()) % 7);
    resultDate.setHours(16);
    resultDate.setMinutes(0);
    resultDate.setSeconds(0);
    addHours(diff, resultDate);

    return resultDate;
}