import * as React from 'react';
import { styles } from '../../generalStyles';
import '../../index.css';
import _ from 'lodash';
import { AuthObject, GlobalState, LeagueObject, Profile } from '../../flowTypes';
import { connect } from 'react-redux';
import { getSuffix, lightBorderLessPadding } from '../../UI Resources';
import { useLocation } from 'react-router';
import { buttonStyles } from './buttonStyles';
import WrappedAnimatingComponent from '../WrappedAnimatingComponent';
import { FixedSizeList as List } from "react-window";
import AutoSizer from 'react-virtualized-auto-sizer';
import { BOX_PADDING, LEADERBOARD_MARGIN_LEFT, MAX_WIDTH, REMAINDER_HEIGHT } from '../../constants';
import { isMobile, isMobileSafari } from 'react-device-detect';

type Props = {
    auth: AuthObject,
    league: LeagueObject,
    leagueId: number
};

type State = {
    index: number,
    showModal: boolean,
    tradingPortfolioIndex: number,
    tradingLeagueId: number,
    sickestApesList: { totalBananas: number, profile: Profile, leagueWins: number }[],
};

class SickestApes extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const sickestApes = props.league?.sickestApes || {};
        this.state = {
            index: 0,
            showModal: false,
            tradingPortfolioIndex: 0,
            tradingLeagueId: 0,
            sickestApesList: Object.keys(sickestApes)
                .sort((a, b) => {
                    if ((sickestApes[b].leagueWins || 0) !== (sickestApes[a].leagueWins || 0)) {
                        return (sickestApes[b].leagueWins || 0) - (sickestApes[a].leagueWins || 0)
                    } else {
                        return (sickestApes[b].profile.gamesPlayed || 0) - (sickestApes[a].profile.gamesPlayed || 0)
                    }
                }
                )
                .map((key) => sickestApes[key]),
        };
    }

    componentDidUpdate(oldProps: Props) {
        const { league } = this.props;
        const { sickestApes } = league || {};

        if (!_.isEqual(sickestApes, oldProps.league?.sickestApes)) {
            const sickestApesList = Object.keys(sickestApes || {})
                .sort((a, b) => {
                    if ((sickestApes[b].leagueWins || 0) !== (sickestApes[a].leagueWins || 0)) {
                        return (sickestApes[b].leagueWins || 0) - (sickestApes[a].leagueWins || 0)
                    } else {
                        return (sickestApes[b].profile.gamesPlayed || 0) - (sickestApes[a].profile.gamesPlayed || 0)
                    }
                }
                ).map((key) => sickestApes[key]);

            this.setState({ sickestApesList });
        }
    }

    getApe = (props: { index: number, style: any }) => {
        const { sickestApesList } = this.state;
        const ape = sickestApesList[props.index];

        return (
            <div style={props.style}>
                <div style={{ ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.fullWidth }}>
                    <div style={{ ...styles.flex, maxWidth: 'calc(100% - 110px)' }}>
                        <div style={{ ...styles.inverseText, ...styles.bold, ...styles.flex, ...styles.centeredContainer, minWidth: 45 }}>{props.index + 1}{!isMobile && getSuffix(props.index + 1)}</div>
                        <div style={{ ...styles.inverseText, marginLeft: LEADERBOARD_MARGIN_LEFT, ...styles.bold, ...styles.flex, ...styles.centerAligned, maxWidth: 'calc(100% - 45px)' }}>
                            <img alt='avatar' style={{ width: 40, borderRadius: 20, ...styles.marginRight }} src={require(`../../assets/avatars-small/${ape.profile.avatarNumber}.png`).default}></img>
                            <div style={{ overflowWrap: 'break-word', overflow: 'hidden', ...(isMobile && styles.smallText) }}>{ape.profile.username}</div>
                        </div>
                    </div>
                    <div style={{ ...styles.flex, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minWidth: 110 }}>
                        <div style={{ ...styles.inverseText, justifyContent: 'flex-end', ...styles.bold, ...styles.flex, ...styles.centerAligned, ...styles.header2 }}>{(ape.leagueWins || 0)}</div>
                        {Boolean(ape.profile.gamesPlayed) && <div style={{ ...styles.dullText, fontSize: 12, ...styles.flex }}>out of {ape.profile.gamesPlayed || 0}</div>}
                    </div>
                </div>
                {lightBorderLessPadding()}
            </div>
        );
    }

    getLeagueStandings() {

        const { sickestApesList } = this.state;

        return (
            <>
                <div style={{ ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.fullWidth, ...styles.marginBottomExtra }}>
                    <div style={{ ...styles.inverseText, ...styles.bold }}>RANK</div>
                    <div style={{ ...styles.inverseText, ...styles.flexGrow, ...styles.bold, marginLeft: LEADERBOARD_MARGIN_LEFT }}>PLAYER</div>
                    <div style={{ ...styles.inverseText, textAlign: 'right', ...styles.bold, width: 110 }}>LEAGUE WINS</div>
                </div>
                <div style={{ ...styles.flexGrow }}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                className="List"
                                height={height}
                                itemCount={sickestApesList.length}
                                itemSize={80.5}
                                width={width}
                                itemData={sickestApesList}
                            >
                                {this.getApe}
                            </List>
                        )}
                    </AutoSizer>
                </div>
            </>

        )
    }

    render(): any {

        return (
            <WrappedAnimatingComponent>
                <div style={{ ...styles.fullWidth, marginTop: 10, maxWidth: MAX_WIDTH, ...styles.flex, ...styles.centerJustified, flexWrap: 'wrap', minHeight: REMAINDER_HEIGHT, ...(isMobileSafari && { paddingBottom: 60 }) }}>
                    <div style={{ ...styles.fullWidth, ...buttonStyles.boxContainer, ...styles.padding, paddingLeft: BOX_PADDING, paddingRight: BOX_PADDING, paddingTop: 0, ...styles.flex, flexDirection: 'column', overflow: 'hidden', ...styles.marginBottomExtra }}>
                        <div style={{ color: '#00F9EA', ...styles.bold, ...styles.header1, ...styles.marginBottomExtra, marginTop: 10 }}>ALL TIME RANKINGS</div>
                        {this.getLeagueStandings()}
                    </div>
                </div>
            </WrappedAnimatingComponent>
        );
    }
}

const mapStateToProps = (state: GlobalState) => {
    return {
        auth: state.auth,
        league: state.league,
    };
};

const WrappedComponent = (props: any) => {
    const location = useLocation();
    const { pathname } = location;
    const leagueId = parseInt(_.last(pathname.split('/')) || '0');

    return <SickestApes leagueId={leagueId} {...props} />
}

export default connect(mapStateToProps, {})(WrappedComponent)