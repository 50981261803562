import * as React from 'react';
import '../../index.css';
import _ from 'lodash';
import { AuthObject, GlobalState, LeagueObject, Portfolio } from '../../flowTypes';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { styles } from '../../generalStyles';
import { buttonStyles } from './buttonStyles';
import { getSuffix, lightBorderLessPadding, lightBorderNoPaddingOnTopLessPadding } from '../../UI Resources';
import { Link } from 'react-router-dom';
import WrappedAnimatingComponent from '../WrappedAnimatingComponent';
import { MechanicalCounter } from 'mechanical-counter';

import medal from '../../assets/medal.png';
import ticket from '../../assets/ticket.png';
import bananaBunch from '../../assets/bananaBunch.png';
import { isMobile } from 'react-device-detect';
import ModalComponent from '../ModalComponent';
import { BOX_PADDING, GREEN, LEAGUE_IDS, LEAGUE_SHORT_TITLES, MAX_WIDTH, RED, REMAINDER_HEIGHT, TEXT_COLOR } from '../../constants';

type Props = {
    auth: AuthObject,
    league: LeagueObject,
    leagueId: number
};

type State = {
    index: number,
    showModal: boolean,
    tradingPortfolioIndex: number,
    tradingLeagueId: number,
    newUsername: string,
    portfolioWithHistory: Portfolio | undefined,
};

class MyStuff extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            index: 0,
            showModal: false,
            tradingPortfolioIndex: 0,
            tradingLeagueId: 0,
            newUsername: '',
            portfolioWithHistory: undefined,
        };
    }

    getLeagueStandings(ranking: number) {
        const { auth, league } = this.props;
        const { uid } = auth || {};
        const { sickestApes } = league || {};

        return (
            <>
                <div className='max-height-desktop' style={{ ...styles.flex, flexDirection: 'column', ...styles.fullWidth, paddingLeft: BOX_PADDING, paddingRight: BOX_PADDING }}>
                    <div style={{ ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.fullWidth, ...styles.marginBottomExtra }}>
                        <div style={{ ...styles.inverseText, width: '33.3%', ...styles.bold }}>RANK</div>
                        <div style={{ ...styles.inverseText, width: '33.3%', ...styles.centerText, ...styles.bold }}>USERNAME</div>
                        <div style={{ ...styles.inverseText, width: '33.3%', textAlign: 'right', ...styles.bold }}>GAINS</div>
                    </div>
                    <div style={{ overflow: 'scroll' }}>
                        {Object.keys(sickestApes || {})
                            .filter(key => key === uid)
                            .map((key) => {
                                const ape = sickestApes[key];
                                const gains = (ape.totalBananas / 100 - 1) * 100;

                                return (
                                    <div key={`leagueStanding-${key}`}>
                                        <div key={`leagueStanding-${key}`} style={{ ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.fullWidth }}>
                                            <div style={{ ...styles.inverseText, width: '33.3%', ...styles.bold }}>{ranking + 1}</div>
                                            <div style={{ ...styles.inverseText, width: '33.3%', ...styles.centerText, ...styles.bold }}>{ape.profile.username}</div>
                                            <div style={{ ...styles.inverseText, width: '33.3%', textAlign: 'right', ...styles.bold, color: gains < 0 ? RED : GREEN }}>{gains < 0 ? '' : '+'}{gains.toFixed(2)}%</div>
                                        </div>
                                        {lightBorderLessPadding(`league-standings-border-${key}`)}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </>

        )
    }

    getHistoryForPortfolio() {
        const { portfolioWithHistory } = this.state;

        return (
            <ModalComponent onRequestClose={() => this.setState({ portfolioWithHistory: undefined })} showModal={Boolean(portfolioWithHistory)} >
                <div style={{ ...styles.marginBottomExtra, ...styles.header3, ...styles.marginBottom, ...styles.inverseText }}><b>{`TRADE HISTORY`}</b></div>
                {lightBorderNoPaddingOnTopLessPadding()}
                <div style={{ ...styles.fullWidth, paddingLeft: 0, paddingRight: 0, ...styles.marginBottomExtra, ...styles.flex, ...styles.centerAligned }}>
                    <div style={{ width: '25%', ...styles.flex, ...styles.centeredContainer }}>
                        <div style={{ ...styles.inverseText, ...styles.smallText, ...styles.bold }}>TICKER</div>
                    </div>
                    <div style={{ width: '25%', ...styles.flex, ...styles.centeredContainer }}>
                        <div style={{ ...styles.inverseText, ...styles.smallText, ...styles.bold }}>TYPE</div>
                    </div>
                    <div style={{ width: '25%', ...styles.flex, ...styles.centeredContainer }}>
                        <div style={{ ...styles.inverseText, ...styles.smallText, ...styles.bold }}>PRICE</div>
                    </div>
                    <div style={{ width: '25%', ...styles.flex, ...styles.centeredContainer }}>
                        <div style={{ ...styles.inverseText, ...styles.smallText, ...styles.bold }}>QUANTITY</div>
                    </div>
                </div>
                {(portfolioWithHistory?.history || []).map(history => {
                    return (
                        <>
                            <div style={{ ...styles.flex, ...styles.centerAligned, ...styles.inverseText, ...styles.bold, marginBottom: 40 }}>
                                <div style={{ width: '25%', ...styles.flex, ...styles.centeredContainer }}>
                                    {history.ticker}
                                </div>
                                <div style={{ width: '25%', ...styles.flex, ...styles.centeredContainer, color: history.type === 'buy' ? '#00F9EA' : '#FFFFFF' }}>
                                    {history.type.toLocaleUpperCase()}
                                </div>
                                <div style={{ width: '25%', ...styles.flex, ...styles.centeredContainer }}>
                                    ${history.price.toFixed(2)}
                                </div>
                                <div style={{ width: '25%', ...styles.flex, ...styles.centeredContainer }}>
                                    {history.quantity.toFixed(2)}
                                </div>
                            </div>
                        </>
                    );
                })}
            </ModalComponent>
        );
    }

    getPortfolios() {
        const { league } = this.props;
        const { portfolios } = league || {};

        let isEmpty = true;

        for (const k of Object.keys(portfolios)) {
            if (!_.isEmpty(portfolios[k])) {
                isEmpty = false;
            }
        }

        if (isEmpty) {
            return (
                <div style={{
                    ...styles.fullWidth, maxWidth: MAX_WIDTH, ...buttonStyles.boxContainer, ...styles.marginBottomExtra, ...styles.padding, paddingLeft: BOX_PADDING, paddingRight: BOX_PADDING
                }}>
                    <div style={{ ...styles.flex, ...styles.flexRowSpaceBetween }}>
                        <div style={{ ...styles.inverseText, ...styles.bold, ...styles.header1, ...styles.marginBottomExtra, color: '#00F9EA' }}>PORTFOLIOS</div>
                    </div>
                    <div style={{ ...styles.flexColumnCentered }}>
                        <div style={{ ...styles.inverseText, ...styles.bold, ...styles.centerText, ...styles.marginBottom }}>You don't have any portfolios right now.</div>
                        <Link
                            to={`/live-games`}
                            className='clickable'
                            style={{ textDecoration: 'none', color: TEXT_COLOR, ...styles.marginBottomExtra, ...styles.roundedCorners, ...styles.baseButton, ...(styles.enabledButton), ...styles.fullWidth, ...{ cursor: 'pointer', justifyContent: 'center', display: 'flex', maxWidth: 300, minHeight: 40 } }}
                        >
                            <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{'BROWSE LEAGUES'}</p>
                        </Link>
                    </div>
                </div >
            )
        }

        return (
            <div style={{
                ...styles.fullWidth, maxWidth: MAX_WIDTH, ...buttonStyles.boxContainer, ...styles.marginBottomExtra, ...styles.padding, paddingLeft: BOX_PADDING, paddingRight: BOX_PADDING
            }}>
                <div style={{ ...styles.flex, ...styles.flexRowSpaceBetween }}>
                    <div style={{ ...styles.inverseText, ...styles.bold, ...styles.header1, ...styles.marginBottomExtra, color: '#00F9EA' }}>PORTFOLIOS</div>
                </div>
                {LEAGUE_IDS.map((leagueId, index) => {
                    const portfolioList = portfolios[leagueId] || [];

                    if (!portfolioList?.length) {
                        return null;
                    }

                    return (<div style={{}}>
                        <div style={{ ...styles.inverseText, ...styles.header3, ...styles.bold, ...styles.marginBottomExtra }}><img alt='ticket' style={{ height: 28, filter: 'invert(1)', marginBottom: -7, marginRight: 5 }} src={ticket} />{LEAGUE_SHORT_TITLES[leagueId]}</div>
                        {portfolioList.map((portfolio, index) => {
                            const gains = (portfolio.totalExpectedBananas / 100 - 1) * 100;
                            const ranking = (portfolio?.ranking || 0) + 1;

                            return (
                                <div key={`portfolio-${index}`} style={{ ...styles.marginBottomExtra }}>
                                    <div key={`portfolio-${index}`} style={{ ...styles.flex, ...styles.centerAligned, ...styles.flexRowSpaceBetween }}>
                                        <div style={{ ...styles.inverseText, ...styles.bold, ...styles.header3, ...styles.flex, ...styles.centerAligned }}>
                                            #{index + 1}
                                        </div>
                                        <div style={{ ...styles.flex, ...styles.centerAligned, justifyContent: 'flex-end', flexGrow: 1, maxWidth: isMobile ? 250 : 300 }}>
                                            <div style={{ ...styles.flex, flexDirection: 'column', alignItems: 'flex-end', ...styles.marginRight }}>
                                                <div style={{ ...styles.inverseText, ...styles.flex, ...styles.centerAligned, ...styles.bold }}>
                                                    <img alt='banana' src={bananaBunch} style={{ height: 20, ...styles.marginRight }} />
                                                    <MechanicalCounter text={`${portfolio.totalExpectedBananas.toFixed(2).split('.')[0]}`} />.<MechanicalCounter text={`${portfolio.totalExpectedBananas.toFixed(2).split('.')[1]}`} />
                                                </div>
                                                <div style={{ ...styles.flex, ...styles.centerAligned, ...styles.smallText, marginTop: 2, ...styles.bold, color: gains < 0 ? RED : GREEN }}>
                                                    {gains < 0 ? '' : '+'}<MechanicalCounter text={`${gains.toFixed(2).split('.')[0]}`} />.<MechanicalCounter text={`${gains.toFixed(2).split('.')[1]}`} />%
                                                </div>
                                            </div>
                                            <Link
                                                to={`/play/${leagueId}`}
                                                className='clickable'
                                                style={{ ...styles.roundedCorners, textDecoration: 'none', ...styles.marginRight, ...styles.baseButton, backgroundColor: '#00F9EA', ...styles.fullWidth, ...{ cursor: 'pointer', display: 'flex', height: 35, maxWidth: 60, alignSelf: 'center' } }}
                                            >
                                                <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', color: TEXT_COLOR, textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}><img alt='medal' src={medal} style={{ height: 20, marginLeft: -5 }} />{ranking}{getSuffix(ranking)}</p>
                                            </Link>
                                            <div style={{}}>
                                                <div
                                                    onClick={() => this.setState({ portfolioWithHistory: { ...portfolio, history: _.clone(portfolio.history)?.reverse() } })}
                                                    className='clickable'
                                                    style={{ ...styles.roundedCorners, ...styles.baseButton, backgroundColor: '#FFFFFF', ...styles.fullWidth, ...{ cursor: 'pointer', display: 'flex', height: 35, alignSelf: 'center', ...styles.padding } }}
                                                >
                                                    <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', color: TEXT_COLOR, textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{'TRADES'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {lightBorderLessPadding(`history-${index}`)}
                    </div>);
                })}
            </div >
        );
    }

    render(): any {

        const { auth, league } = this.props;
        const { profile, uid, localAccount } = auth || {};
        const { username, avatarNumber, gamesPlayed, gamesWon = 0 } = profile || {};
        const { sickestApes } = league || {};

        const sickestApesList = Object.keys(sickestApes || {})
            .sort((a, b) => (sickestApes[b].profile?.gamesWon || 0) - (sickestApes[a].profile?.gamesWon || 0));

        const ranking = sickestApesList.indexOf(uid);

        return (
            <WrappedAnimatingComponent>
                {this.getHistoryForPortfolio()}
                <div style={{ ...styles.fullWidth, overflow: 'scroll', marginTop: 10, height: REMAINDER_HEIGHT, ...styles.flex, flexDirection: 'column', ...styles.centerAligned }}>
                    <div style={{ ...styles.fullWidth, maxWidth: MAX_WIDTH }}>
                        {Boolean(uid) && Boolean(localAccount) && <div style={{ ...buttonStyles.boxContainer, ...styles.padding, paddingLeft: BOX_PADDING, paddingRight: BOX_PADDING, ...styles.marginBottom }}>
                            <div style={{ ...styles.flex, ...styles.flexRowSpaceBetween }}>
                                <div style={{ ...styles.inverseText, ...styles.bold, ...styles.header1, ...styles.marginBottomExtra, color: '#00F9EA' }}>PROFILE</div>
                                <Link
                                    className='clickable no-select'
                                    to={'/edit-profile'}
                                    style={{ ...styles.marginBottom, ...styles.roundedCorners, ...styles.baseButton, ...({ backgroundColor: '#00F9EA' }), ...styles.fullWidth, ...{ color: TEXT_COLOR, marginTop: 3, cursor: 'pointer', justifyContent: 'center', display: 'flex', maxWidth: 100, height: 30, paddingLeft: 0, paddingRight: 0, textDecoration: 'none' } }}
                                >
                                    <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{'EDIT'}</p>
                                </Link>
                            </div>
                            <div style={{ ...styles.fullWidth, paddingLeft: 0, paddingRight: 0, marginBottom: 30, ...styles.flex, ...styles.centeredContainer }}>
                                <img style={{ width: 100, borderRadius: 50, ...styles.marginRight }} src={require(`../../assets/avatars-medium/${avatarNumber}.png`).default} alt='profilepic' />
                                <div style={{ ...styles.inverseText, ...styles.bold, ...styles.header2 }}>{username}</div>
                            </div>
                            <div style={{ ...styles.fullWidth, paddingLeft: 0, paddingRight: 0, ...styles.marginBottomExtra, ...styles.flex, ...styles.centerAligned }}>
                                <div style={{ ...styles.marginRightExtra }}>
                                    <div style={{ ...styles.inverseText, ...styles.smallText, ...styles.bold }}>Games Played</div>
                                    <div style={{ ...styles.inverseText, ...styles.smallText, ...styles.bold }}>{gamesPlayed || 0}</div>
                                </div>
                                <div style={{ ...styles.marginRightExtra }}>
                                    <div style={{ ...styles.inverseText, ...styles.smallText, ...styles.bold }}>Games Won</div>
                                    <div style={{ ...styles.inverseText, ...styles.smallText, ...styles.bold }}>{gamesWon}</div>
                                </div>
                                <div style={{ ...styles.marginRightExtra }}>
                                    <div style={{ ...styles.inverseText, ...styles.smallText, ...styles.bold }}>All-time Ranking</div>
                                    <div style={{ ...styles.inverseText, ...styles.smallText, ...styles.bold }}>{ranking + 1}{getSuffix(ranking + 1)}</div>
                                </div>
                            </div>
                        </div>}
                        {this.getPortfolios()}
                    </div>
                </div>
            </WrappedAnimatingComponent>
        );
    }
}

const mapStateToProps = (state: GlobalState) => {
    return {
        auth: state.auth,
        league: state.league,
    };
};

const WrappedComponent = (props: any) => {
    const location = useLocation();
    const { pathname } = location;
    const leagueId = parseInt(_.last(pathname.split('/')) || '0');

    return <MyStuff leagueId={leagueId} {...props} />
}

export default connect(mapStateToProps, {})(WrappedComponent)