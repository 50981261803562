import { BORDER_RADIUS, BOX_COLOR } from "../../constants";
import { styles } from "../../generalStyles";

const TOGGLE_BUTTON_HEIGHT = 45;
export const buttonStyles = {
    walletConnectedButton: {
        color: 'white',
        border: '1.2px solid white',
        borderRadius: BORDER_RADIUS,
        fontSize: '18',
        fontWeight: 'bold',
        ...styles.baseButton,
        ...styles.centeredContainer,
        ...styles.flex,
        height: TOGGLE_BUTTON_HEIGHT - 5,
        paddingRight: 10,
        paddingLeft: 10,
        maxWidth: 300,
        fontStyle: 'italic'
    },
    navigationToggleContainer: {
        backgroundColor: BOX_COLOR,
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        height: 30,
        borderRadius: BORDER_RADIUS,
        ...styles.shadow
    },
    navigationItem: {
        display: 'flex',
        width: '50%',
        justifyContent: 'center',
        height: 30,
        borderRadius: BORDER_RADIUS,
        alignItems: 'center',
        fontSize: '18',
        fontWeight: 'bold',
        textDecoration: 'none' 
    },
    navigationItemSelected: {
        backgroundColor: 'white',
        color: BOX_COLOR,
        fontStyle: 'italic',
    },
    navigationItemUnselected: {
        backgroundColor: BOX_COLOR,
    },
    connectedButton: {
        height: 30,
    },
    boxContainer: {
        backgroundColor: BOX_COLOR,
        ...styles.shadow,
        borderRadius: BORDER_RADIUS,
    }
}