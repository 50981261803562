import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import snapeHead from './assets/snapeHead.png';
Sentry.init({
    dsn: "https://eb63c348684b48ffb0e44cfdf32d548e@o1276234.ingest.sentry.io/6471647",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const store = createStore(
    reducers,
    compose(applyMiddleware(reduxThunk))
);

const isDown = false;

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                {isDown ?
                    <div style={{ color: 'white', textAlign: 'center', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <img alt='ape' src={snapeHead} style={{ marginBottom: 10, height: 50, width: 50 }}></img>
                        <div>
                        Maintenance in progress...
                        </div>
                        Please check back later today.
                    </div>
                    : <App />
                }
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
