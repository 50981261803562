export const actionTypes = {
    UPDATE_ACCESS_TOKEN: 'UPDATE_ACCESS_TOKEN',
    UPDATE_BANK_INFO: 'UPDATE_BANK_INFO',
    UPDATE_CASH_BALANCE: 'UPDATE_CASH_BALANCE',
    UPDATE_LINK_TOKEN: 'UPDATE_LINK_TOKEN',
    UPDATE_TRANSACTIONS: 'UPDATE_TRANSACTIONS',
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_LOCAL_ACCOUNT: 'UPDATE_LOCAL_ACCOUNT',
    UPDATE_WEB_3: 'UPDATE_WEB_3',
    UPDATE_LOCAL_ACCOUNT_AND_WEB_3: 'UPDATE_LOCAL_ACCOUNT_AND_WEB_3',
    UPDATE_NETWORK_ID: 'UPDATE_NETWORK_ID',
    UPDATE_PORTFOLIOS: 'UPDATE_PORTFOLIOS',
    UPDATE_LEAGUES: 'UPDATE_LEAGUES',
    UPDATE_STOCKS: 'UPDATE_STOCKS',
    UPDATE_NONCES: 'UPDATE_NONCES',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    UPDATE_SICKEST_APES: 'UPDATE_SICKEST_APES',
    UPDATE_SHOW_CONNECT_MODAL: 'UPDATE_SHOW_CONNECT_MODAL',
    UPDATE_WINNERS: 'UPDATE_WINNERS',
    UPDATE_PLAYER_COUNTS: 'UPDATE_PLAYER_COUNTS',
};
