import React from 'react';
import './App.css';
import { styles } from './generalStyles';
import ConnectWallet from './ConnectWallet';
// @ts-ignore
import { Transition } from 'react-transition-group';

import walletIcon from './assets/walletConnectedMobile.png';
import coin from './assets/snowape_logo.png';
import gameOver from './assets/gameOver.png';
import polygonLogo from './assets/polygonLogo.png';

import { isMobile } from 'react-device-detect';
import { buttonStyles } from './components/Navigation/buttonStyles';
import { AuthObject, GlobalState, LeagueObject, LeagueStandings, Stock, StockNumbers } from './flowTypes';
import { connect } from 'react-redux';
import { updateLeagues, updatePortfolios, updateSickestApes, updateStocks, updateShowConnectModal, updateNonces, updateLeagueWinners, updateLeaguePlayerCounts } from './actions/leagueActions';
import { getDatabase, limitToLast, onValue, orderByChild, query, ref, set, Unsubscribe } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { updateUser, updateProfile } from './actions/authActions';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { CHAIN_ID, LEAGUE_IDS, MAX_WIDTH, SECONDARY_BUTTON_BACKGROUND_COLOR, SYMBOL } from './constants';
import ModalComponent from './components/ModalComponent';
import { lightBorderNoPaddingOnTopLessPadding } from './UI Resources';

type Props = {
    auth: AuthObject,
    league: LeagueObject,
    updateLeagues: typeof updateLeagues,
    updatePortfolios: typeof updatePortfolios,
    updateStocks: typeof updateStocks,
    updateUser: typeof updateUser,
    updateProfile: typeof updateProfile,
    updateSickestApes: typeof updateSickestApes,
    updateShowConnectModal: typeof updateShowConnectModal,
    updateNonces: typeof updateNonces,
    updateLeagueWinners: typeof updateLeagueWinners,
    updateLeaguePlayerCounts: typeof updateLeaguePlayerCounts,
};
type State = {
    opacity: number,
    showConnect: boolean,
    showCoins: boolean,
    winningAmount: number,
    showWallet: boolean,
};

let portfolioListeners: Unsubscribe[] = [];
let playerCountListeners: Unsubscribe[] = [];
let leagueListeners: Unsubscribe[] = [];
let nonceListener: Unsubscribe;
let candlesListener: Unsubscribe;
let sickestApesListener: Unsubscribe;
let profileListener: Unsubscribe;
let winningListener: Unsubscribe;

class App extends React.Component<Props, State> {


    constructor(props: Props) {
        super(props);
        this.state = {
            opacity: 0,
            showConnect: false,
            showCoins: isMobile,
            winningAmount: 0,
            showWallet: false,
        };
    }

    componentDidMount() {
        const { updateUser, updateShowConnectModal } = this.props;

        setTimeout(() => {
            this.setState({ showConnect: true });
        }, 1000);

        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {

            updateUser({ uid: user?.uid || '' });

            const {
                auth: authProps,
            } = this.props;

            const { networkId } = authProps || {};
            if (window.localStorage.getItem('hasBeenOnWebsite')) {
                updateShowConnectModal((networkId && networkId !== CHAIN_ID) || !user);
            }

            window.localStorage.setItem('hasBeenOnWebsite', 'true');

            this.fetchData();
        });
    }

    async fetchData() {

        const leagueIds = LEAGUE_IDS;


        portfolioListeners.forEach((p) => p());
        portfolioListeners.splice(0);

        playerCountListeners.forEach((p) => p());
        playerCountListeners.splice(0);

        leagueListeners.forEach((l) => l());
        leagueListeners.splice(0);

        nonceListener && nonceListener();
        candlesListener && candlesListener();
        sickestApesListener && sickestApesListener();
        profileListener && profileListener();
        winningListener && winningListener();

        const { auth, updateLeagues, updateStocks, updateProfile, updateSickestApes } = this.props;
        const { uid, localAccount, web3 } = auth || {};

        setInterval(async () => {
            for (const leagueId of leagueIds) {
                const tx = window.localStorage.getItem(`currentTx-${leagueId}`);
                if (tx && uid && localAccount) {
                    const currentBlockNumber = await web3?.eth.getBlockNumber() || 0;
                    const txBlockNumber = (await web3?.eth.getTransaction(tx))?.blockNumber || 0;
                    if (txBlockNumber && currentBlockNumber - txBlockNumber >= 20) {
                        const functions = getFunctions();
                        const getPortfolios = httpsCallable(functions, 'leagues-getPortfolios');
                        try {
                            await getPortfolios({ uid: localAccount, leagueId });
                        } catch (e) {
                            return;
                        }
                        window.localStorage.removeItem(`currentTx-${leagueId}`);
                    }
                }
            }
        }, 500);

        const database = getDatabase();
        const noncesRef = ref(database, 'currentNonces');
        const candlesRef = ref(database, 'dailyCandles');
        const sickestApesRef = ref(database, 'sickestApes');
        const profileRef = ref(database, `profiles/${uid}`);
        const winningRef = ref(database, `winners`);

        nonceListener = onValue(noncesRef, (snapshot) => {
            const { league, auth, updateNonces } = this.props;
            const { uid } = auth || {};
            const { stocks } = league || {};

            const nonces = snapshot.val() as { [leagueId: number]: number };

            updateNonces(nonces);

            portfolioListeners.forEach((p) => p());
            portfolioListeners.splice(0);

            leagueListeners.forEach((l) => l());
            leagueListeners.splice(0);

            updateLeagues({}, stocks, uid);

            for (const leagueId of leagueIds) {

                const leagueRef0 = ref(database, `/leagues/league-${leagueId}/nonce-${nonces[leagueId]}/leagueStandings`);
                const leagueListener = onValue(
                    query(leagueRef0, orderByChild('totalExpectedBananas'), limitToLast(50)),
                    (snapshot) => {
                        const { league, auth } = this.props;
                        const { uid } = auth || {};
                        const { stocks, leagues } = league || {};

                        const result = snapshot.val() as LeagueStandings;
                        if (!result) return;
                        updateLeagues({ ...leagues, [leagueId]: { leagueStandings: result, leaguePlayerCount: 0 } }, stocks, uid);
                    });

                leagueListeners.push(leagueListener);

                const playerCountRef = ref(database, `/leagues/league-${leagueId}/nonce-${nonces[leagueId]}/leaguePlayerCount`);
                const playerCountListener = onValue(playerCountRef, (snapshot) => {
                    const { league, updateLeaguePlayerCounts } = this.props;
                    const { leaguePlayerCounts } = league || {};

                    let playerCounts = leaguePlayerCounts;
                    const result = snapshot.val() as number;
                    if (!result) return;

                    playerCounts[leagueId] = result;
                    updateLeaguePlayerCounts(playerCounts);
                });

                playerCountListeners.push(playerCountListener);
            }
        });

        candlesListener = onValue(candlesRef, (snapshot) => {
            const { league, auth } = this.props;
            const { uid } = auth || {};
            const { leagues } = league || {};

            const stocks = snapshot.val() as { [ticker: string]: StockNumbers };
            const stocksToAdd: Stock[] = [];
            for (const key of Object.keys(stocks)) {
                if (stocks[key].type === 'stock') {
                    stocksToAdd.push({ ...(stocks[key]), ticker: key, price: stocks[key].close });
                }
            }

            updateStocks(stocksToAdd);
            updateLeagues(leagues, stocksToAdd, uid);
        });

        sickestApesListener = onValue(query(sickestApesRef, orderByChild('leagueWins'), limitToLast(50)), (snapshot) => {
            updateSickestApes(snapshot.val());
        });

        profileListener = onValue(profileRef, (snapshot) => {
            updateProfile(snapshot.val());
        });

        winningListener = onValue(winningRef, (snapshot) => {
            const { auth, updateLeagueWinners } = this.props;
            const { uid } = auth || {};

            if (snapshot.val()) {
                const info = snapshot.val() as { [leagueId: string]: { nonce: number, winAmount: number, uid: string[], winnerUsername: string } };
                const nonces = [info[0].nonce, info[1].nonce];

                let winnerUsernames = [];
                /* for (const leagueId of Object.keys(info)) {
                    winnerUsernames.push(info[leagueId].winnerUsername || '');
                } */
                winnerUsernames = [info[0].winnerUsername, '', ''];
                updateLeagueWinners(winnerUsernames);

                // if no local nonces, new user. set items and go away
                if (!window.localStorage.getItem('winningNonces')) {
                    window.localStorage.setItem('winningNonces', `${info[0].nonce},${info[1].nonce}`);
                    return;
                }

                const localNonces = (window.localStorage.getItem('winningNonces') || '0,0').split(',').map(s => parseInt(s));
                for (let i = 0; i < nonces.length; i++) {
                    if (nonces[i] === localNonces[i]) return;
                }
                window.localStorage.setItem('winningNonces', `${info[0].nonce},${info[1].nonce}`);

                let winningAmount = 0;

                for (const leagueId of Object.keys(info)) {
                    for (const winner of (info[leagueId].uid || [])) {
                        if (winner === uid) {
                            winningAmount += (info[leagueId].winAmount || 0);
                        }
                    }
                    winnerUsernames.push(info[leagueId].winnerUsername || '');
                }
                this.setState({ winningAmount: (winningAmount || -1) });
            }
        });
    }

    acknowledgeWinning = () => {
        const { auth } = this.props;
        const { uid } = auth || {};
        const database = getDatabase();
        const winningsRef = ref(database, `winners/${uid}`);
        set(winningsRef, 0);
        this.setState({ winningAmount: 0 });
    }

    render() {
        const { auth } = this.props;
        const { showConnect, winningAmount, showWallet } = this.state;

        const { localAccount, uid } = auth || {};

        const validLogin = localAccount && uid && localAccount === uid;

        const duration = 300;

        const defaultStyle = {
            transition: `all ${duration}ms ease-in-out`,
            opacity: 0,
        };

        const connectTransitionStyles: { [key: string]: any } = {
            entering: { opacity: 1, marginTop: 0 },
            entered: { opacity: 1, marginTop: 0 },
            exiting: { opacity: 0, marginTop: 100 },
            exited: { opacity: 0, marginTop: 100 },
        };

        return (
            <div style={{
                backgroundColor: 'transparent',
                ...styles.fullHeight,
                ...styles.flex,
                flexDirection: 'column',
                margin: 'auto',
                paddingLeft: 10,
                paddingRight: 10,
                overflow: 'hidden'
            }}>
                <div style={{ maxWidth: MAX_WIDTH, margin: 'auto', ...(isMobile && { marginBottom: 0, marginTop: 0 }), ...styles.flex, ...styles.flexRowSpaceBetween, ...styles.centerAligned, ...styles.paddingExtra, width: '100%', paddingBottom: 0, paddingRight: 0, paddingLeft: 0 }}>
                    <a href='/live-games'><img alt='coin' style={{ height: 45, marginTop: 5 }} src={coin}></img></a>
                    {!isMobile && validLogin && <img alt='polygon' src={polygonLogo} style={{ height: 35, marginTop: 2 }}></img>}
                    <div className='clickable' onClick={() => this.setState({ showWallet: true })}>
                        {validLogin && (!isMobile ? <div style={{ ...buttonStyles.walletConnectedButton }} >WALLET CONNECTED</div>
                            : <img alt='connected' src={walletIcon} style={{ ...buttonStyles.connectedButton }} ></img>)}
                    </div>
                </div>
                <Transition in={showConnect} timeout={1300}>
                    {(state: any) =>
                        <div style={{
                            ...defaultStyle,
                            ...connectTransitionStyles[state],
                        }}>

                            <div style={{
                                margin: 'auto',
                                position: 'relative',
                                ...styles.centerAligned,
                                display: 'flex',
                                flexDirection: 'column',
                                ...styles.roundedCorners,
                                width: '100%'
                            }}>
                                <ConnectWallet />
                            </div>
                        </div>
                    }</Transition>
                <ModalComponent showModal={Boolean(winningAmount)} onRequestClose={this.acknowledgeWinning}>
                    <div style={{ ...styles.flexColumnCentered }}>
                        <img alt='game-over' src={gameOver} style={{ width: '100%', marginBottom: 20 }}></img>
                        <div style={{ ...styles.inverseText, ...styles.bold, ...styles.header1, color: '#00F9EA' }}>{winningAmount > 0 ? 'YOU WON!' : 'LEAGUES HAVE ENDED'}</div>
                        <div style={{ ...styles.inverseText, ...styles.bold, ...styles.centerText, ...styles.marginBottom }}>{winningAmount > 0 ? `${winningAmount.toFixed(2)} ${SYMBOL} HAS BEEN SENT TO YOUR WALLET.` : 'THIS WEEK\'S LEAGUES HAVE ENDED. YOU DIDN\'T WIN ANYTHING THIS WEEK.'}</div>
                        <div
                            onClick={this.acknowledgeWinning}
                            className='clickable'
                            style={{ ...styles.marginBottomExtra, ...styles.roundedCorners, ...styles.baseButton, ...(styles.enabledButton), ...styles.fullWidth, ...{ cursor: 'pointer', justifyContent: 'center', display: 'flex', maxWidth: 300, minHeight: 40 } }}
                        >
                            <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>{winningAmount > 0 ? 'NICE' : 'OK'}</p>
                        </div>
                    </div>
                </ModalComponent>
                <ModalComponent showModal={showWallet} onRequestClose={() => this.setState({ showWallet: false })}>
                    <div style={{ ...styles.flex, flexDirection: 'column' }}>
                        <div style={{ ...styles.marginBottomExtra, ...styles.header3, ...styles.marginBottom, ...styles.inverseText }}><b>{'WALLET CONNECTED'}</b></div>
                        {lightBorderNoPaddingOnTopLessPadding()}
                        <div style={{ marginBottom: 20, ...styles.flexRowSpaceBetween, ...styles.flex }}>
                            <div style={{ ...styles.inverseText, ...styles.bold, ...(isMobile && styles.smallText) }}>Wallet Address</div>
                            <div style={{ ...styles.inverseText, ...styles.bold, ...(isMobile && styles.smallText) }}>{localAccount.slice(0, 5)}...{localAccount.slice(localAccount.length - 4)}</div>
                        </div>
                        <div
                            onClick={() => this.setState({ showWallet: false })}
                            className='clickable'
                            style={{ ...styles.roundedCorners, ...styles.baseButton, backgroundColor: SECONDARY_BUTTON_BACKGROUND_COLOR, ...styles.fullWidth, ...{ cursor: 'pointer', justifyContent: 'center', display: 'flex', minHeight: 40 } }}
                        >
                            <p style={{ ...styles.text, ...styles.bold, ...{ fontStyle: 'italic', textAlign: 'center', margin: 0, display: 'flex', alignItems: 'center' } }}>GOT IT</p>
                        </div>
                    </div>
                </ModalComponent>
            </div >
        );
    }
}

const mapStateToProps = (state: GlobalState) => {
    return {
        auth: state.auth,
        league: state.league,
    };
};

export default connect(mapStateToProps, {
    updateLeagues,
    updatePortfolios,
    updateStocks,
    updateUser,
    updateProfile,
    updateSickestApes,
    updateShowConnectModal,
    updateNonces,
    updateLeagueWinners,
    updateLeaguePlayerCounts
})(App);
